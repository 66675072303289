import React, { Component } from 'react';
import CustomLineChart from '../../comonents/CustomLineChart';
import AppTableFilter from '../../../../../components/crud/table/AppTableFilter';
import Api from '../../../../../logic/api/Api';

class AndroidUninstall extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // Load data and initialize graph
    this._loadData();
  }

  _filter(filter) {
    this._loadData(filter);
  }

  async _loadData(filter = []) {
    try {
      const result = await Api.call('stats/stores/google/uninstalls', {
        filter
      });

      this.setState({
        ...result.data
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="main-inner col-10" style={{ paddingBottom: 0 }}>
          <CustomLineChart
            chartId="android-uninstall"
            data={this.state}
            label="android uninstallations"
            graph="uninstall"
          />
        </div>
        <div
          className="col-2"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            padding: 5
          }}
        >
          <div style={{ flex: 1, flexGrow: 1 }}>
            <AppTableFilter
              columnForm
              onInit={comp => (this._tableFilter = comp)}
              filter={[
                {
                  name: 'fromDate',
                  type: 'datetime',
                  field: 'created',
                  operation: 'gte',
                  label: 'From Date'
                },
                {
                  name: 'toDate',
                  type: 'datetime',
                  field: 'created',
                  operation: 'lte',
                  label: 'To Date'
                }
              ]}
              {...this.props}
              onFilter={filter => this._filter(filter)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AndroidUninstall;
