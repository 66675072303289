import React from 'react';
import { AppPage, AppWidget } from '../../../../components';
import LatestBulkLoads from '../components/LatestBulkLoads';
import BulkLoadModel from '../models/BulkLoadModel';
import BulkLoadInfo from '../components/BulkLoadInfo';
import { AppFetcher } from '../../../../components';
import BulkLoadRecords from '../components/BulkLoadRecords';
import BreadcrumbHelper from '../../../../util/BreadcrumbHelper';

export default class BulkLoadDetails extends React.Component {

  constructor(props) {
    super(props);

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Bulk Load',
        link: '/tools/bulk-load'
      }
    ]);
  }

  componentDidMount() {
    this._startPooling();
  }

  _startPooling() {
    setTimeout(() => {
      this._pool();
    }, 2000);
  }

  _pool() {
    if(this._fetcher) {

      let record = this._fetcher.getRecord();
      if(record && record.status != 'processing') return;

      this._fetcher.refresh(true);

      if(this._table) {
        this._table._refresh(true, true);
      }
    }

    setTimeout(() => {
      this._pool();
    }, 5000)
  }

  _renderContent(item) {

    if(!this.firstLoad) {
      BreadcrumbHelper.addCrumb(item.name);
      this.firstLoad = true;
    }

    return (
      <AppPage title={'Load Details'} icon={'info'}>
        <div className='row'>
          <div className='col-sm-4'>
            <AppWidget title={item.name} icon={null} padded>
              <BulkLoadInfo bulkLoad={item}/>
            </AppWidget>
          </div>

          <div className='col-sm-8'>
            <AppWidget title='Bulk load items' icon={'list'}>
              <BulkLoadRecords bulkLoad={item} onInit={ref => this._table = ref }/>
            </AppWidget>
          </div>
        </div>

      </AppPage>
    );
  }
  render() {
    return (
      <AppFetcher
          ref={fetcher => this._fetcher = fetcher}
          model={BulkLoadModel}
          id={this.props.match.params.id}
          renderContent={record => this._renderContent(record)}
        >
        </AppFetcher>
    );
  }
}
