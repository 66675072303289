export default class BaseModule {
  getName() {
    return '-noname-';
  }

  getPermission(): string {
    return '';
  }

  getNavigation() {}

  getRoutes() {}
}
