import BaseModel from "../../../../logic/model/BaseModel";

export default class BulkLoadRecordModel extends BaseModel {

  /**
   * Get list url
   */
  getListUrl() {
    return 'bulk-load/details/records';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'bulk-load/record/details';
  }

  getDetailsRequestData(data) {
    return {
      id: data.id,
      bulkLoadId: data.bulkLoadId
    }
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'token',
        label: 'Token',
        sortable: false,
        type: 'field'
      },
      {
        name: 'amount',
        label: 'Amount',
        sortable: false,
        type: 'field'
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ]
  }

}
