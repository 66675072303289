import BaseModel from '../../../../../../@launchpad/logic/model/BaseModel';

export default class LoadReportModel extends BaseModel {
  /**
   * Get list url
   */
  getListUrl() {
    return 'load-report/list';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'load-report/details';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'payment-device-load-unload/export';
  }

  getValidationRules() {
    return {
      from: {
        presence: {
          allowEmpty: false,
          message: '^Please select from date'
        }
      },
      to: {
        presence: {
          allowEmpty: false,
          message: '^Please select to date'
        }
      }
    };
  }
}
