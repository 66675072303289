import React from 'react';
import { LoadingOverlay } from '../../../components';
import Api from '../../../logic/api/Api';

export default class NewUserWidget extends React.Component {

  constructor() {
    super();

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this._loadStats();
  }

  async _loadStats() {
    const result = await Api.call('stats/users');

    if(result.success) {
      this.setState({
        ...result.data,
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="widget">
        <div className="dark-blur"></div>
        <div className="widget-header"><h3 className="widget-title"><i className="fa fa-users" aria-hidden="true"></i> New Users</h3></div>
        <div className="widget-inner">
          { this.state.loading ?
            <LoadingOverlay/> :
            <div className="widget-new-customers">
              <div>
                <i className="fa fa-users" aria-hidden="true"></i>
                <p className="customers-number">{this.state.total} <span>total users</span></p>
              </div>
              <ul>
                <li>Last week</li>
                <li> {this.state.lastWeek}</li>
              </ul>
              <ul>
                <li>Last month</li>
                <li> {this.state.lastMonth}</li>
              </ul>
              <ul>
                <li>Last year</li>
                <li> {this.state.lastYear}</li>
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}
