import React from 'react';
import { OverrideService } from '@launchpad/logic/services';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'cards';

export default class CardsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'card.*';
  }

  getNavigation() {
    return [
      {
        label: 'Cards',
        icon: 'credit-card',
        path: null,
        permission: 'cards.*',
        children: [
          {
            label: 'Cards',
            icon: 'credit-card',
            path: '/cards',
            permission: 'cards.*'
          },
          {
            label: 'Replace card requests',
            icon: 'credit-card-alt',
            path: '/cards/replace-requests',
            permission: 'replace-requests.*'
          },
          // add if cardPresentations: true
          OverrideService.getOverrides().card.cardPresentations && {
            label: 'Card Presentations',
            path: '/card-management/card-presentations',
            icon: 'credit-card'
          }
        ].filter(Boolean)
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}cards`}
        load={() => import('../../modules/cards/Cards')}
      />,
      <AppRoute
        path={`${currentUrl}card-management`}
        load={() =>
          import('./card/card-presentations/PaymentDevicePresentations')
        }
      />
    ];
  }
}
