import React from 'react';


export default class ProgressBarComponent extends React.Component {
  constructor(props) {
    super(props)

    // this.state = {
    //   percentage: 45
    // }
  }



  render() {

    let props = {
      ...this.props
    };

    return (
      <div>
        <ProgressBar percentage={this.props.percentage} showProgressBar={this.props.showProgressBar} {...props} />
      </div>
    )
  }
}

const ProgressBar = (props) => {
  return (
      <div className="progress-bar">
        <Filler percentage={props.percentage} />
      </div>
    )
}

const Filler = (props) => {
  return <div className="filler" style={{ width: `${props.percentage}%` }} />
}

ProgressBarComponent.defaultProps = {
  showProgressBar: true
}
