import React from 'react';
import Chart from 'chart.js';
import { LoadingOverlay } from '../../../../components';

export default class CustomPieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    Chart.defaults.global.defaultFontColor = 'white';
    setTimeout(() => {
      this.setState(
        {
          loading: false
        },
        () => {
          this._loadData();
        }
      );
    }, 1000);
    // Load data and initialize graph
  }

  async _loadData() {
    this.renderOrderDotsChart();
  }

  renderOrderDotsChart() {
    const config = {
      type: 'pie',
      data: {
        labels: this.props.labels,
        datasets: [
          {
            data: this.props.data,
            borderColor: '#fff',
            borderWidth: 2,
            fill: true,
            backgroundColor: ['#3DDC84', '#555555'],
            hoverBackgroundColor: ['#568203', '#000']
          }
        ]
      },
      options: {
        legend: {
          display: true
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    };

    const ctx = document.getElementById(this.props.chartId).getContext('2d');
    window.myScatter = new Chart(ctx, config);
  }

  render() {
    return (
      <div className="widget-inner" style={{ padding: '10px' }}>
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <div>
            <canvas
              id={this.props.chartId}
              style={{ width: '100%', height: '350px' }}
              width="600"
              height="350"
            />
          </div>
        )}
      </div>
    );
  }
}
