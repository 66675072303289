import React from 'react';

export default class AppActiveSign extends React.Component {

  render() {
    if(this.props.value === 0 || this.props.value === false) {
      return <i className="fa fa-times" aria-hidden="true" style={{ color: '#FF2323',  fontSize: 21 }}></i>
    }else{
      return <i className="fa fa-check" aria-hidden="true" style={{ color: '#4fd615', fontSize: 21 }}></i>
    }
  }
}

AppActiveSign.defaultProps = {
  value: null
};
