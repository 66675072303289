import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router';
import BulkLoadModel from '../models/BulkLoadModel';
import AppUploadField from '../../../../components/crud/AppUploadField';
import {
  FormHandler,
  FormSubmitWrapper,
  InputField
} from '../../../../components';

class NewBulkLoad extends React.Component {
  constructor(props) {
    super(props);

    this.formHandler = new FormHandler(
      this,
      new BulkLoadModel({
        name: '',
        bulkFile: ''
      })
    );
  }

  _onSuccess(data) {
    const load = data.data;
    return this.props.history.push(`/tools/bulk-load/details/${load.id}`);
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <div
          style={{
            padding: '0 5% 5% 5%',
            textAlign: 'center',
            border: '1px solid transparent'
          }}
        >
          <FormSubmitWrapper
            buttonText="Upload"
            formHandler={formHandler}
            to="/tools/bulk-load"
            noCancelButton
            onSuccess={data => this._onSuccess(data)}
          >
            <InputField
              materialProps={{
                fullWidth: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              customInput={TextField}
              label="Name"
              type="text"
              name="name"
              value={this.state.record.name}
              errors={formHandler.getErrors()}
              handler={formHandler}
            />

            <AppUploadField
              label="*Upload XLSX file"
              name="bulkFile"
              handler={formHandler}
              errors={formHandler.getErrors()}
              accept=".xlsx"
              value={this.state.record.bulkFile}
            />

            <p>
              Example file can be downloaded{' '}
              <a
                href={require('../../../../assets/example_file.xlsx')}
                style={{ fontWeight: 'bold' }}
                target="_blank"
                download
              >
                here
              </a>
            </p>
          </FormSubmitWrapper>
        </div>
      </div>
    );
  }
}

export default withRouter(NewBulkLoad);
