const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';

export const showModal = (id: any, props: any)  => ({
  type: SHOW_MODAL,
  payload: {
    id,
    props
  }
});

export const hideModal = (id = null)  => ({
  type: HIDE_MODAL,
  payload: {
    id
  }
});
