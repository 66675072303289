import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Chart from 'chart.js';
import { LPOverridable } from '../../../../components';
import TotalActiveUsers from './components/TotalActiveUsers';
import TotalInstallations from './components/TotalInstallations';
import TotalUninstallations from './components/TotalUninstallations';
import TotalCrashChart from './components/TotalCrashChart';
import TotalInstallationsChart from './components/TotalInstallationsChart';
import TotalUninstallationsChart from './components/TotalUninstallationsChart';
import AnaliticsAndroidModel from '../android/models/AnalyticsAndroidModel';
import AppButtonModal from '../../../../components/modal/AppButtonModal';
import AppleReportsUploadField from '../apple/components/AppleReportsUploadField';

interface HTMLInputElement extends HTMLElement {
  getContext(arg0: string): any;
  /**
   * Returns the value of the data at the cursor's current position.
   */
  value: string;
}

declare global {
  interface Window {
    barChartConfig: any;
    myScatter: any;
  }
}

export default class AnalyticsTotals extends Component<HTMLInputElement, any> {
  _tableComponent: any;

  constructor(props: Readonly<HTMLInputElement>) {
    super(props);
    this.state = {
      androidData: null,
      appleData: {
        InstallsLastWeek: 56,
        UninstallsLastWeek: 15
      }
      // installAndroidData: null,
      // uninstallAndroidData: null,
      // crashesAndroidData: null,
      // installAppleData: 1000,
      // uninstallAppleData: 360,
      // crashesAppleData: 1500
    };
  }

  componentDidMount() {
    this._loadAndroidData();
  }

  async _loadAndroidData() {
    try {
      const result = await new AnaliticsAndroidModel().getOverviewData();
      this.setState({
        androidData: result
        // installAndroidData: Number(result.Installs.googleInstallsInLastWeek),
        // uninstallAndroidData: Number(
        //   result.Uninstalls.googleUninstallsInLastWeek
        // ),
        // crashesAndroidData: Number(result.Crashes.googleCrashesInLastWeek)
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      // installAndroidData,
      // uninstallAndroidData,
      // crashesAndroidData,
      // installAppleData,
      // uninstallAppleData,
      // crashesAppleData
      androidData,
      appleData
    } = this.state;
    return (
      <div className="dashboard-management">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h1>
            <i className="fa fa-pie-chart" aria-hidden="true" /> Totals
          </h1>
          <div style={{ maxWidth: 300 }}>
            <AppButtonModal
              buttonText={
                <span>
                  <i className="fa fa-upload" aria-hidden="true" /> Upload Apple
                  Reports
                </span>
              }
              buttonClassName="button-success"
            >
              <AppleReportsUploadField />
            </AppButtonModal>
          </div>
        </div>
        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <LPOverridable name="pages.analytics.widgets.top">
            <TotalActiveUsers />
            <TotalInstallations android={androidData} apple={appleData} />
            <TotalUninstallations android={androidData} apple={appleData} />
          </LPOverridable>
        </div>
        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <LPOverridable name="pages.analytics.widgets.bottom">
            <TotalCrashChart />
            <TotalInstallationsChart />
            <TotalUninstallationsChart />
          </LPOverridable>
        </div>
      </div>
    );
  }
}
