import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BottomWaveForCard from '../crud/BottomWaveForCard';
import { LPOverridable } from '../../index';

class AppLayoutWithCard extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <LPOverridable name={'login.page.content'}>
        <div className='screen-layout'>
          <div className='app-card'>
            <LPOverridable name={'login.page.logo'}>
              <img className='logo' src={require('../../assets/Launchpad_logoWhite.png')} alt="white-logo" />
            </LPOverridable>
            <div className='child-container'>
              {this.props.children}
            </div>
            <LPOverridable name={'login.page.bottom'}>
              <BottomWaveForCard />
            </LPOverridable>
          </div>
          <LPOverridable name={'login.page.bottomText'}>

            <div className='sign-up-holder'>
              <p>New user?</p>
              <Link to='' className="sign-up-link">Sign Up</Link>
            </div>
          </LPOverridable>
        </div>
      </LPOverridable>
    )
  }
}

export default AppLayoutWithCard;
