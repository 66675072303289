import React from 'react';
import { AppInfoList } from '../../../../components/widgets';
import moment from 'moment';
import { AppBadge } from '../../../../components';
import Api from '../../../../logic/api/Api';

export default class BulkLoadInfo extends React.Component {

  _download(bulkLoad) {
    Api.guardedDownload(bulkLoad.file);
  }

  render() {

    let { bulkLoad } = this.props;

    let details = [
      {
        label: 'Name',
        value: bulkLoad.name
      },
      {
        label: 'Created',
        value: moment(bulkLoad.created).format('DD/MM/YYYY HH:mm:ss')
      },
      {
        label: 'Record count',
        value: bulkLoad.recordCount
      },
      {
        label: 'Successful loads',
        value: bulkLoad.successCount
      },
      {
        label: 'Failed loads',
        value: bulkLoad.failureCount
      },
      {
        label: 'Status',
        value: <AppBadge text={bulkLoad.status}/>
      },
      {
        label: 'Download uploaded file',
        value: <a href="javascript:void(0)" onClick={() => this._download(bulkLoad)}><AppBadge text={'download'}/></a>
      }
    ]
    return (
      <AppInfoList items={details}/>
    )
  }
}
