import React from 'react';
import LoadingOverlay from '../LoadingOverlay';

export default class AppFetcher extends React.Component {

  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      record: null,
      items: [],
      pagination: {
        pages: 0,
        limit: 20,
        total_items: 0
      },
      loadedTimes: 0
    }
  }

  /**
   * Called on mount
   */
  componentDidMount() {
    this._loadData();
  }

  /**
   * Get model
   */
  _getModel() {
    if (this.model) return this.model;

    this.model = new this.props.model();

    return this.model;
  }

  async refresh(silent = false) {
    this._loadData(silent);
  }

  async _loadData(silent = false) {
    let result = null;

    if(!silent) {
      this._loadingOn();
    }

    if(this.props.id) {
      // Load single item

      try {
        result = await this._getModel().get(this.props.id);
      } catch(e) {}

    }

    if(this.props.fetchWith) {
      try {
        result = await this.props.fetchWith();
      } catch(e) {
      }
    }

    if(!result) {
      this.setState({
        notFound: true
      })
    } else {
      this.setState({
        record: result,
        loadedTimes: ++this.state.loadedTimes
      })
    }

    this._loadingOff();
  }

  _loadingOn() {
    this.setState({
      loading: true
    })
  }

  _loadingOff() {
    this.setState({
      loading: false
    })
  }

  getRecord() {
    return this.state.record;
  }

  render() {

    if(this.state.loading) {
      return <LoadingOverlay/>
    }

    if(this.state.notFound) {
      return <div>Not found!</div>
    }

    if(!this.state.record)  return null;

    return this.props.renderContent(this.state.record);
  }
}


AppFetcher.defaultProps = {
  renderContent: () => null
}
