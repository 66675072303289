import React from 'react';
import { LoadingOverlay } from '../../../../../components';
import Api from '../../../../../logic/api/Api';

export default class TotalActiveUsers extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: false
      },
      () => {
        this._loadStats();
      }
    );
  }

  async _loadStats() {
    const result = await Api.call('stats/stores/users/active');

    if (result.success) {
      this.setState({
        ...result.data,
        loading: false
      });
    }
  }

  render() {
    const activeUsers = this.state.ActiveUsers;
    if (!activeUsers) return null;
    return (
      <div className="widget widget-auto-height">
        <div className="dark-blur" />
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-users" aria-hidden="true" /> Total Active Users
          </h3>
        </div>
        <div className="widget-inner">
          {this.state.loading ? (
            <LoadingOverlay />
          ) : (
            <div className="widget-new-customers">
              <div>
                <i className="fa fa-users" aria-hidden="true" />
                <p className="customers-number">
                  {activeUsers.Android + activeUsers.Apple}{' '}
                  <span>active users</span>
                </p>
              </div>
              <ul>
                <li>Android</li>
                <li>{activeUsers.Android}</li>
              </ul>
              <ul>
                <li>Apple</li>
                <li>{activeUsers.Apple}</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
