import React from 'react';
import SwitchButton from '../../../../@launchpad/components/crud/SwitchButton';
import Api from '../../../../@launchpad/logic/api/Api';
import LoadingOverlay from '../../../../@launchpad/components/crud/LoadingOverlay';
import Auth from '../../../../@launchpad/logic/services/AuthService';

class FxNotificationsReports extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleFxNotifications(id: any, status: any) {
    this.setState({
      loading: true
    });

    this.changeStatusFxNotifications(id, status)
      .then(() => {
        this.setState({
          loading: false
        });
        Auth.refreshAuth();
      })
      .catch(() => {});
  }

  handleFxReportNotifications(id: any, status: any) {
    this.setState({
      loading: true
    });

    this.changeStatusFxReportNotifications(id, status)
      .then(() => {
        this.setState({
          loading: false
        });
        Auth.refreshAuth();
      })
      .catch(() => {});
  }

  async changeStatusFxNotifications(id: any, status: any) {
    const response = await Api.call('user/notifications/fx/configure', {
      id,
      enabled: !!status
    });

    return response;
  }

  async changeStatusFxReportNotifications(id: any, status: any) {
    const response = await Api.call('user/notifications/fx-report/configure', {
      id,
      enabled: !!status
    });

    return response;
  }

  render() {
    const { user } = this.props;

    return (
      <>
        <div className="d-flex flex-row align-items-center justify-content-between my-3">
          Receive FX conversion notifications
          <SwitchButton
            isChecked={!!user.fxNotifications}
            onChange={(val: any) => this.handleFxNotifications(user.id, val)}
          />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between my-3">
          Receive monthly FX exchange reports
          <SwitchButton
            isChecked={!!user.fxReportNotifications}
            onChange={(val: any) =>
              this.handleFxReportNotifications(user.id, val)
            }
          />
        </div>
      </>
    );
  }
}

export default FxNotificationsReports;
