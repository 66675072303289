import React, { Component } from 'react';
import {
  AppLayoutWithCard,
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput
} from '../../../index';
import { Link, Redirect } from 'react-router-dom';
import LoginModel from '../models/LoginModel';
import { loginTriggerAction } from '../AuthActions';
import { connect } from 'react-redux';
import { REQUIRE_OTP } from '../../../logic/api/ActionCodes';

class LoginPage extends Component<any, any> {
  formHandler: FormHandler;

  componentDidMount() {
    this.setState({
      record: {
        ...this.state.record,
        email: '',
        password: '',
        remember: false
      }
    });
  }
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginModel({
        email: ' ',
        password: ' '
      }),
      {
        redirect: false
      }
    );
  }

  onSubmitSuccess(data: {
    success: any;
    code: any;
    data: { token: any; account: any };
  }) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 3000);
    }

    if (data.code === REQUIRE_OTP) {
      this.props.history.push('/login/otp', { otp: data.data });
    }
  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }

    if (data.success && !data.message) {
      return 'Authentication successful!';
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/"></Redirect>;
    }
    let formHandler = this.formHandler;
    return (
      <div className="public-form-holder">
        <h4>SIGN IN</h4>
        <div className="form-wrapper">
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            to="/"
            buttonText="SIGN IN"
            noCancelButton
            getModalMessage={(data: { success: any; message: any }) =>
              this._getModalMessage(data)
            }
          >
            <div className="login-input-wrapper">
              <InputField
                className={'input-group'}
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.email}
                label="E-mail"
                name="email"
                // placeholder={'E-mail'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.email}
                handler={formHandler}
              />
            </div>
            <div className="login-input-wrapper">
              <InputField
                className={'input-group'}
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.password}
                label="Password"
                type="password"
                name="password"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.password}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
          >
            <Link
              to="/forgot-password"
              style={{
                color: 'white',
                textDecoration: 'none',
                fontWeight: 'lighter',
                fontSize: 15
              }}
            >
              {' '}
              Forgot password?{' '}
            </Link>
          </div>

          {/* <AppCheckboxInput
            name='remember'
            id='rememberField'
            htmlFor='rememberField'
            labelText='Remember me next time'
            value={this.state.remember}
            handler={this.formHandler}
          /> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    payload: { token: any }; // Initial state
  }) => void
) => {
  return {
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
