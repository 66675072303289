import React from 'react';
import _ from 'lodash';
import { AppConfig } from '../../../config/types';
import Config from '../../../config';

export default class OverrideService {
  static configuration: any;

  static overrides: any;

  /**
   * Refresh auth
   */
  static storeConfiguration(configuration: any) {
    this.configuration = configuration;
    Config.merge(this.getConfig());
  }

  /**
   * Get modules
   */
  static getModules() {
    return this.configuration.modules || null;
  }

  /**
   * Gt all overrides
   */
  static getOverrides() {
    return this.configuration && this.configuration.overrides
      ? this.configuration.overrides
      : null;
  }

  /**
   * Get config
   */
  static getConfig(): AppConfig {
    return this.configuration && this.configuration.config
      ? this.configuration.config
      : {};
  }

  /**
   * Get routes
   */
  static getRoutes() {
    return _.get(this.configuration, 'routes', []);
  }

  /**
   * Get side navigation
   *
   * @param appNavigation Actual app navigation
   */
  static getSideNavigation(appNavigation) {
    const alterFunction = _.get(this.configuration, 'getNavigation', null);

    if (alterFunction) {
      return alterFunction(appNavigation);
    }

    return appNavigation;
  }

  static thisOrOverridenComponent(OriginalComponent, overridePath) {
    const SelectedButton: React.FC<any> = props => {
      if (_.get(OverrideService.getOverrides(), overridePath)) {
        const CustomButton: any = _.get(
          OverrideService.getOverrides(),
          overridePath
        );
        return <CustomButton {...props} />;
      }
      return <OriginalComponent {...props} />;
    };
    return SelectedButton;
  }

  // static getThisOrOverridenColors() {
  //   if (OverrideService.getStyles() && OverrideService.getStyles().colors) {
  //     return {
  //       ...colors,
  //       ...OverrideService.getStyles().colors
  //     };
  //   }
  //   return {
  //     ...colors
  //   };
  // }
}
