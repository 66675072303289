import React from 'react';
import Config from '../../config';

export default class AppImage extends React.Component {
  getSource() {
    const source = this.props.src;

    if (!source || source.length === 0) {
      return require('../../assets/no-image.png');
    }

    if (!source.substring) {
      return source;
    }

    if (source.substring(0, 8) === '/static/') {
      return source;
    }
    if (source[0] === '/') {
      const location = new URL(Config.url);
      return location.origin + source;
    }

    return source;
  }

  render() {
    const source = this.getSource();

    const props = {
      ...this.props,
      src: source
    };

    return <img {...props} alt="" />;
  }
}
