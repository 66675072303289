import BaseModel from "../../../logic/model/BaseModel";

export default class NotificationUserGroupModel extends BaseModel {
  /**
   * Get details url
   */
  getDetailsUrl() {
    return "notification-user-group/details";
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return "notification-user-group/save";
  }

  getListUrl() {
    return "notification-user-group/list";
  }

  getDeleteUrl() {
    return "notification-user-group/delete";
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: "id",
        label: "Id",
        sortable: true,
        type: "field"
      },
      {
        name: "name",
        label: "Name",
        sortable: true,
        type: "field"
      },
      {
        name: "description",
        label: "Description",
        sortable: false,
        type: "field"
      },
      {
        name: null,
        label: "Actions",
        sortable: false,
        type: "actions"
      }
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: "^Please enter name"
        }
      },
      description: {
        presence: {
          allowEmpty: false,
          message: "^Please enter description"
        }
      }
    };
  }
}
