import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'dictionary';

export default class DictionaryModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'multilingual.*';
  }

  getNavigation() {
    return [
      {
        label: 'Multilingual',
        icon: 'language',
        path: '/multilingual',
        permission: 'multilingual.*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}multilingual`}
        load={() => import('./Dictionary')}
      />
    ];
  }
}
