import React, { Component } from 'react';
import CustomLineChart from '../../comonents/CustomLineChart';
import AppTableFilter from '../../../../../components/crud/table/AppTableFilter';

class AppleCrashes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [5, 140, 6, 70, 20, 50, 6, 18, 22, 91, 34, 4],
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Avg',
        'Sep',
        'Okt',
        'Nov',
        'Dec'
      ]
    };
  }

  render() {
    return (
      <div className="row">
        <div className="main-inner col-10" style={{ paddingBottom: 0 }}>
          <CustomLineChart
            chartId="apple-crashes"
            data={this.state.data}
            labels={this.state.labels}
            label="apple crashes"
          />
        </div>
        <div
          className="col-2"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            padding: 5
          }}
        >
          <div style={{ flex: 1, flexGrow: 1 }}>
            <AppTableFilter
              columnForm
              onInit={comp => (this._tableFilter = comp)}
              filter={[
                {
                  name: 'fromDate',
                  type: 'datetime',
                  field: 'created',
                  operation: 'gte',
                  label: 'From Date'
                },
                {
                  name: 'toDate',
                  type: 'datetime',
                  field: 'created',
                  operation: 'lte',
                  label: 'To Date'
                }
              ]}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AppleCrashes;
