export const APP_MOUNTED = 'APP_MOUNTED';
export const SHOULD_SHOW_IS_LANDING = 'SHOULD_SHOW_IS_LANDING';

export const appMountedAction = () => ({
  type: APP_MOUNTED,
  payload: null
});

export const shouldShowIsLandingAction = isLanding => ({
  type: SHOULD_SHOW_IS_LANDING,
  payload: isLanding
});
