import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PublicLayout from './components/layout/PublicLayout';
import DefaultLayout from './components/layout/DefaultLayout';
import { appMountedAction } from './logic/store/AppActions';
import { refreshAuthAction } from './modules/auth/AuthActions';
import { AppRoute, AppRouter } from './components';
import { OverrideService } from './logic/services';
// import AuthService  from '../admin/logic/services';

class App extends Component<any, any> {
  getInnerPaths(props: { match: { path: any } }) {
    // if(props.match.params && props.match.params.locale) {
    //   locale = props.match.params.locale;
    // }

    // let url = props.match.path;
    // url = url.replace(/\/$/, '');

    const customRoutes = OverrideService.getRoutes();

    return (
      <Switch>
        {customRoutes}
        {/* <AppRoute path={`${url}/login`} component={PublicLayout}  unAuthenticatedOnly /> */}
        <AppRoute
          path="/forgot-password"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute path="/login" component={PublicLayout} unAuthenticatedOnly />
        <AppRoute path="" component={DefaultLayout} authenticatedOnly />
      </Switch>
    );
  }

  render() {
    if (!this.props.ready) return null;

    const InnerRouting = (props: any) => this.getInnerPaths(props);

    return (
      <AppRouter>
        <div className="App">
          <Switch>
            <Route path="/" component={InnerRouting} />
          </Switch>
          <div id="modalHolder" className="modal-holder" />
        </div>
      </AppRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: {
  (arg0: { type: string; payload: null }): void;
  (arg0: { type: string }): void;
}) => {
  return {
    appMounted: () => {
      return dispatch(appMountedAction());
    },
    refreshAuth: () => {
      return dispatch(refreshAuthAction());
    }
  };
};

const mapStateToProps = (state: { ready: any }) => {
  return {
    ready: state.ready
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
