import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import BulkLoadDashboard from './pages/BulkLoadDashboard';
import BulkLoadDetails from './pages/BulkLoadDetails';
import BulkLoadRecordDetails from './pages/BulkLoadRecordDetails';

export default class Countries extends Component {

  render() {
    return(
      <Switch>
        <Route path="/tools/bulk-load" exact component={BulkLoadDashboard}/>
        <Route path="/tools/bulk-load/details/:id" exact component={BulkLoadDetails}/>
        <Route path="/tools/bulk-load/details/:id/record/:recordId" exact component={BulkLoadRecordDetails}/>
      </Switch>
    );
  }
}

