import React, { Component } from 'react';

export default class FormComponent extends Component {
  printChildren() {
    const elements = [];
    const { state } = this.props.formHandler;
    this.props.children.forEach((child, key) => {
      let fieldError = null;
      if (child.props && child.props.name) {
        for (let i = 0; i < state.errors.length; i++) {
          const error = state.errors[i];
          if (child.props.name === error.field) {
            fieldError = error;
            break;
          }
        }
      }

      if (fieldError) {
        elements.push(
          React.cloneElement(child, {
            error: fieldError,
            key: `input-field-${fieldError.field}`
          })
        );
      } else {
        elements.push(child);
      }
    });

    return elements;
  }

  /**
   * Render template
   *
   * @returns {XML}
   */
  render() {
    // let submissionStatus = this.props.formHandler.getSubmissionStatus();
    const formClass = this.props.formHandler.getFormClass();
    // let loadingComponent = this.props.formHandler.getLoadingComponent();
    const children = this.printChildren();

    return (
      <div className="login-form relative-position">
        {/* {loadingComponent} */}
        <form
          className={formClass}
          id={this.props.id}
          method=""
          action=""
          onSubmit={this.props.formHandler.handleSubmit.bind(
            this.props.formHandler
          )}
        >
          {children}
        </form>
      </div>
    );

    // return (
    //     <div className="register-form relative-position">
    //         {loadingComponent}
    //         <form className={formClass} id="registerForm" method="" action="" onSubmit={this.props.formHandler.handleSubmit.bind(this.props.formHandler)}>
    //             {children}
    //         </form>
    //     </div>
    // );
  }
}
