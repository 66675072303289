import React from 'react';
import { connect } from 'react-redux';
import ProfileImage from '../../../components/images/ProfileImage';
import { AppIcon } from '../../../index';
import DateHelper from '../../../util/DateHelper';
import {
  logoutTriggerAction,
  loginTriggerAction
} from '../../../modules/auth/AuthActions';
import { Link } from 'react-router-dom';

class AppTopBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      menuOpened: false,
      notificatonsOpened: false
    };
  }

  showMenu() {
    this.setState({
      menuOpened: true
    });
  }

  hideMenu() {
    this.setState({
      menuOpened: false
    });
  }

  showNotifications() {
    this.setState({
      notificatonsOpened: true
    });
  }

  hideNotifications() {
    this.setState({
      notificatonsOpened: false
    });
  }

  getElementStyle() {
    let style = {
      opacity: '0',
      top: '54px'
    };
    if (this.state.menuOpened) {
      style.opacity = '1';
      style.top = '38px';
    }
    return style;
  }

  getLinkStyle() {
    let linkStyle = {
      display: 'none'
    };
    if (this.state.menuOpened) {
      linkStyle.display = 'block';
    }
    return linkStyle;
  }

  getNotifications() {
    let notificatonStyle = {
      opacity: '1',
      display: 'none'
    };
    if (this.state.notificatonsOpened) {
      notificatonStyle.opacity = '1';
      notificatonStyle.display = 'block';
    }
    return notificatonStyle;
  }

  notifications() {
    var x = document.getElementById('notif') as any;
    if (x.style.display === 'none') {
      x.style.display = 'block';
    } else {
      x.style.display = 'none';
    }
  }

  logoutUser(token: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    this.props.userLoggedOut(token);
  }

  render() {
    let style = this.getElementStyle();
    let linkStyle = this.getLinkStyle();
    let notificatonStyle = this.getNotifications();
    let profile = this.props.profile;
    return (
      <div
        className="dashboard-menu-top"
        onMouseLeave={this.hideMenu.bind(this)}
      >
        <div
          className="dark-blur"
          onMouseLeave={this.hideNotifications.bind(this)}
        ></div>
        <p className="left">BANKVAULT</p>
        {/* <div id="notificatons-holder" style={{}}>
          <a href="javascript:void(0)" onClick={this.notifications.bind(this)} ><i className="fa fa-bell" aria-hidden="true" style={{ color: '#fff' }}></i><i className="badge badge-danger">1</i></a>
          <div style={{ position: 'relative' }}>
            <div id="notif" className="notifications" style={notificatonStyle}>
              <div className="dark-blur"></div>
              <ul >
                <li>
                  <div className="notification-text">Customer <Link to="/customers/details" onClick={this.notifications.bind(this)}>Hilton</Link> skipped order this July</div>
                  <div className="notification-actions">
                    <Link to="/customers/details" onClick={this.notifications.bind(this)}>details</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* <div className="search">
          <form>
            <div className="form-group">
              <input type="text" name="search" placeholder="Search..." />
            </div>
          </form>
        </div> */}
        <a className="right" onMouseEnter={this.showMenu.bind(this)}>
          <i className="fa fa-cogs" aria-hidden="true"></i> {profile ? profile.fullName : profile.email}
        </a>
        <div className="superadmin-dropdown" style={style}>
          <div className="dark-blur"></div>
          <div>
            {/* <a href="#" style={linkStyle}><i className="fa fa-user" aria-hidden="true"></i> My account</a> */}
            <Link to="/my-account" style={linkStyle}>
              <i className="fa fa-user" aria-hidden="true" /> My account
            </Link>
            <a
              href="javascript:void(0)"
              onClick={token => this.logoutUser(token)}
              style={linkStyle}
            >
              <i className="fa fa-eject" aria-hidden="true"></i> Log out
            </a>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = (state: { auth: { token: any; profile: any; }; }) => {
//     return {
//         token: state.auth.token,
//         profile: state.auth.profile,
//     }
// }

const mapDispatchToProps = (dispatch: {
  (arg0: { type: string; payload: {} }): void;
  (arg0: { type: string; payload: { token: any; account: any } }): void;
}) => {
  return {
    userLoggedOut: () => {
      return dispatch(logoutTriggerAction());
    },
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTopBar);
