import React, { Component } from 'react';
import CustomLineChart from '../../comonents/CustomLineChart';
import AppTableFilter from '../../../../../components/crud/table/AppTableFilter';

class AppleInstall extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      last7Days: [
        { date: '2020-04-23', dailyDeviceInstalls: 166 },
        { date: '2020-04-24', dailyDeviceInstalls: 155 },
        { date: '2020-04-25', dailyDeviceInstalls: 117 }
      ]
    };
  }

  render() {
    return (
      <div className="row">
        <div className="main-inner col-10" style={{ paddingBottom: 0 }}>
          <CustomLineChart
            chartId="apple-install"
            graph="install"
            data={this.state}
            label="apple installations"
          />
        </div>
        <div
          className="col-2"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            padding: 5
          }}
        >
          <div style={{ flex: 1, flexGrow: 1 }}>
            <AppTableFilter
              columnForm
              onInit={comp => (this._tableFilter = comp)}
              filter={[
                {
                  name: 'fromDate',
                  type: 'datetime',
                  field: 'created',
                  operation: 'gte',
                  label: 'From Date'
                },
                {
                  name: 'toDate',
                  type: 'datetime',
                  field: 'created',
                  operation: 'lte',
                  label: 'To Date'
                }
              ]}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AppleInstall;
