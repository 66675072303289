import React, { Component } from 'react';
import CustomPieChart from './CustomPieChart';
import AnaliticsAndroidModel from '../android/models/AnalyticsAndroidModel';

class CompareAndroidApple extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      installAndroidData: null,
      uninstallAndroidData: null,
      crashesAndroidData: null,
      installAppleData: 1000,
      uninstallAppleData: 360,
      crashesAppleData: 1500,
      labels: ['Android', 'Apple']
    };
  }

  componentDidMount() {
    this._loadAndroidData();
  }

  async _loadAndroidData() {
    try {
      const result = await new AnaliticsAndroidModel().getOverviewData();
      this.setState({
        installAndroidData: Number(result.Installs.totalGoogleInstalls),
        uninstallAndroidData: Number(result.Uninstalls.totalGoogleUninstalls),
        crashesAndroidData: Number(result.Crashes.totalGoogleCrashes)
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      installAndroidData,
      uninstallAndroidData,
      crashesAndroidData,
      installAppleData,
      uninstallAppleData,
      crashesAppleData
    } = this.state;
    return (
      <div className="dashboard-management">
        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <div className="widget">
            <div className="dark-blur" />
            <div className="widget-header">
              <h3 className="widget-title">
                <i className="fa fa-pie-chart" aria-hidden="true" />
                Total Installations
              </h3>
            </div>
            <CustomPieChart
              chartId="android-apple-install"
              data={[installAndroidData, installAppleData]}
              labels={this.state.labels}
            />
          </div>
          <div className="widget">
            <div className="dark-blur" />
            <div className="widget-header">
              <h3 className="widget-title">
                <i className="fa fa-pie-chart" aria-hidden="true" />
                Total Uninstallations
              </h3>
            </div>
            <CustomPieChart
              chartId="android-apple-uninstall"
              data={[uninstallAndroidData, uninstallAppleData]}
              labels={this.state.labels}
            />
          </div>
          <div className="widget">
            <div className="dark-blur" />
            <div className="widget-header">
              <h3 className="widget-title">
                <i className="fa fa-pie-chart" aria-hidden="true" />
                Total Crashes
              </h3>
            </div>
            <CustomPieChart
              chartId="android-apple-crashes"
              data={[crashesAndroidData, crashesAppleData]}
              labels={this.state.labels}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CompareAndroidApple;
