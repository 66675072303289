import React from 'react';

export default class AppBlur extends React.Component {

  render() {
    return (
      <div className="element-with-blur">
        <div className="dark-blur"></div>
        {this.props.children}
      </div>
    )
  }
}
