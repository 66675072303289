import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppIcon
} from '../../../../../@launchpad/index';
import LoadReportModel from './models/LoadReportModel';
import 'react-datepicker/dist/react-datepicker.css';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';

export default class LoadReport extends React.Component {
  constructor() {
    super();

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoadReportModel({
        from: '',
        to: ''
      })
    );
    BreadcrumbHelper.setCrumbs([
      {
        label: 'Load Report'
      }
    ]);
  }

  render() {
    const { formHandler } = this;

    return (
      <div className="credit-card-management">
        <h1>
          <i className="fa fa-file" aria-hidden="true" /> Load Report
        </h1>
        <div className="element-with-blur">
          <div className="dark-blur" />
          <div className="box-header">
            <h2>
              <i className="fa fa-file" /> Load Report
            </h2>
          </div>
          <div className="p-5">
            <FormSubmitWrapper
              formHandler={formHandler}
              to="/tools/load-report"
              buttonText="Generate"
              dontRedirect
            >
              <div className="container">
                <div className="col-lg-8 d-flex flex-column flex-lg-row align-items-center justify-content-between m-auto">
                  <div className="col-6 my-3 py-3 px-0 px-lg-3">
                    <h5>From date</h5>
                    <div className="position-relative">
                      <InputField
                        materialProps={{
                          fullWidth: true
                        }}
                        placeholder="Please select from date"
                        name="from"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={
                          this.state.record.from &&
                          moment(this.state.record.from).format('DD/MM/YYYY')
                        }
                        handler={formHandler}
                        clearError={this.state.record.from}
                      />
                      <AppIcon
                        name="fa.calendar-o"
                        style={{
                          fontSize: 22,
                          color: 'white',
                          position: 'absolute',
                          top: 32,
                          right: 5
                        }}
                      />
                      <div className="load-report">
                        <DatePicker
                          selected={this.state.record.from}
                          placeholderText="Please select from date"
                          maxDate={new Date()}
                          onChange={date =>
                            this.setState(prevState => ({
                              record: {
                                ...prevState.record,
                                from: date
                              }
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-3 py-3 px-0 px-lg-3">
                    <h5>To date</h5>
                    <div className="position-relative">
                      <InputField
                        materialProps={{
                          fullWidth: true
                        }}
                        placeholder="Please select to date"
                        name="to"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={
                          this.state.record.to &&
                          moment(this.state.record.to).format('DD/MM/YYYY')
                        }
                        handler={formHandler}
                        clearError={this.state.record.to}
                      />
                      <AppIcon
                        name="fa.calendar-o"
                        style={{
                          fontSize: 22,
                          color: 'white',
                          position: 'absolute',
                          top: 32,
                          right: 5
                        }}
                      />
                      <div className="load-report">
                        <DatePicker
                          selected={this.state.record.to}
                          maxDate={new Date()}
                          onChange={date =>
                            this.setState(prevState => ({
                              record: {
                                ...prevState.record,
                                to: date
                              }
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormSubmitWrapper>
          </div>
        </div>
      </div>
    );
  }
}
