import React from 'react';
import { LoadingOverlay } from '../../../components';
import Api from '../../../logic/api/Api';
import { Link } from 'react-router-dom';

export default class SubscribersWidget extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false, // back to true when get endpoint,
      totalSubscribers: 3643,
      lastWeek: 223,
      lastMonth: 1068,
      lastYear: 3643
    };
  }

  componentDidMount() {
    this._loadStats();
  }

  async _loadStats() {
    // const result = await Api.call('stats/users');
    // if(result.success) {
    //   this.setState({
    //     ...result.data,
    //     loading: false
    //   });
    // }
  }

  render() {
    return (
      <div className="widget widget-auto-height">
        <div className="dark-blur"></div>
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-users" aria-hidden="true"></i> Total Subscribers
          </h3>
        </div>
        <div className="widget-inner">
          {this.state.loading ? (
            <LoadingOverlay />
          ) : (
            <div className="widget-new-customers">
              <div>
                <i className="fa fa-users" aria-hidden="true"></i>
                <p className="customers-number">
                  {this.state.totalSubscribers} <span>subscribers</span>
                </p>
              </div>
              <ul>
                <li>Last week</li>
                <li> {this.state.lastWeek}</li>
              </ul>
              <ul>
                <li>Last month</li>
                <li> {this.state.lastMonth}</li>
              </ul>
              <ul>
                <li>Last year</li>
                <li> {this.state.lastYear}</li>
              </ul>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 30
                }}
              >
                <Link to="push-notifications/add-new" className="button-danger">
                  Send Push Notification
                </Link>
                <Link to="/user-groups/add" className="button-info">
                  Create Customer Group
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
