import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import AppTopBar from '../../components/layout/components/AppTopBar';
import AppSideBar from '../../components/layout/components/AppSideBar';
import BreadcrumbHelper from '../../util/BreadcrumbHelper';
import Breadcrumb from '../../components/elements/shared/Breadcrumbs';
import ModuleService from '../../logic/module/ModuleService';

class DefaultLayout extends Component<any, any> {
  moduleService: ModuleService;

  modules: any[];

  constructor(props: any) {
    super(props);

    this.moduleService = new ModuleService();

    this.modules = this.moduleService.getModules();

    this.state = {};
  }

  componentDidMount() {
    if (this.props.profile.oneTimePasswordUsed) {
      this.props.history.push('/my-account');
    }
  }

  /**
   * Clear breacrumbs
   */
  componentWillUpdate() {
    BreadcrumbHelper.clearCrumbs();
  }

  /**
   * Fast fix for top scrolling
   */
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  _getInnerElements() {
    return (
      <Switch>{this.moduleService.getRoutes(this.props.match.url)}</Switch>
    );
  }

  /**
   * Render default layout
   *
   * @returns {XML}
   */
  render() {
    const innerElements = this._getInnerElements();

    return (
      <div>
        <AppTopBar />
        <div className="dashboard">
          <AppSideBar />
          <Breadcrumb />
          <div className="dashboard-main">{innerElements}</div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(DefaultLayout);
