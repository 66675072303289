import React from 'react';
import ReactJson from 'react-json-view'
import Api from '../../../../../logic/api/Api';
import { AppFetcher } from '../../../../../components';

export default class BulkLoadRecordServiceCall extends React.Component {

  _renderContent(data) {
    return (
      <table className="table">
        <tbody>
          <tr className="tr-dark-bg">
            <td>Request</td>
            <td>Response</td>
          </tr>
          <tr>
            <td>
              <ReactJson
                theme="chalk"
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                style={{
                  backgroundColor: 'transparent'
                }}
                src={JSON.parse(data.request)}
              />
            </td>
            <td>
              <ReactJson
                theme="chalk"
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                style={{
                  backgroundColor: 'transparent'
                }}
                src={JSON.parse(data.response)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  async _getServiceCallDetails() {
    let record = this.props.record;

    let response = await Api.call('bulk-load/record/details/service-call', {
      id: record.id,
      bulkLoadId: record.bulkLoad.id
    });

    return response.data;
  }
  render() {
    return (
      <AppFetcher
          fetchWith={() => this._getServiceCallDetails()}
          renderContent={data => this._renderContent(data)}
        >
        </AppFetcher>
    );
  }
}
