import moment from 'moment';

export function date(dateValue, format = 'DD/MM/Y') {
  return moment(dateValue).format(format)
}
export function time(dateValue, format = 'H:m') {
  return moment(dateValue).format(format)
}
export function fullDate(dateValue, format = 'DD MMM YYYY, HH:mm') {
  return moment(dateValue).format(format)
}

export function getHumanized(dateValue) {
  return date(dateValue, 'ddd, MMM Do');
}

export function add(date, count, intervals) {
  if (date == null) {
    date = moment();
  }
  return moment(date).add(count, intervals);
}

export function year(date = null) {
  if (!date) {
    return moment().year();
  }

  return moment(date).year();
}

export function expiryDate(dateValue) {
  return date(dateValue, 'MM/YYYY');
}

export function toApiDate(dateValue, fromFormat = 'DD/MM/YYYY') {
  return moment(dateValue, fromFormat).format('YYYY-MM-DD');
}

/**
 * TODO: Return greeting by time
 */
export function getGreeting() {
  let today = new Date()
  let curHr = today.getHours()

  if (curHr < 12) {
    return 'Good morning';
  } else if (curHr < 18) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
}


export default {
  date,
  time,
  fullDate,
  getHumanized,
  add,
  expiryDate,
  toApiDate,
  getGreeting,
  year
}
