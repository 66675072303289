import appStore from '../store/AppStore';

export default class PermissionService {
  static hasPermission(permission: string) {
    const permissions = appStore.getState().auth.permissions;
    const profile = appStore.getState().auth.profile;

    if (!profile) return false;

    if (profile.isSuperAdmin) return true;

    return this.checkPermissionSet(permission, permissions.grantedPermissions);
  }

  static checkPermissionSet(permission, grantedPermissions) {
    // If not returned by backend, enable all
    if(!grantedPermissions){
      return true;
    }
    // Check if denied
    if (grantedPermissions.deny) {
      const result = this.checkPermissionList(
        permission,
        grantedPermissions.deny
      );

      if (result) {
        return false;
      }
    }

    if (grantedPermissions.allow) {
      const result = this.checkPermissionList(
        permission,
        grantedPermissions.allow
      );

      if (result) {
        return true;
      }
    }

    return false;
  }

  static checkPermissionList(permission, permissionList) {
    for (let i = 0; i < permissionList.length; i++) {
      if (this.matchPermission(permission, permissionList[i])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Check if needle passes the permission
   * TODO: This could be done using regular expressions
   * @param needle
   * @param permission
   */
  static matchPermission(needle, permission) {
    if (permission === '*') return true;

    if (permission === needle) return true;

    const splits = needle.split('.');

    for (let i = splits.length; i > 0; i--) {
      const permissionToCheck = splits.slice(0, i).join('.');
      if (
        permissionToCheck === permission ||
        `${permissionToCheck}.*` === permission
      ) {
        return true;
      }
    }
    return false;
  }
}
