import React from 'react';
import BulkLoadModel from '../models/BulkLoadModel';
import { Link } from 'react-router-dom';
import { AppTable } from '../../../../components';

export default class LatestBulkLoads extends React.Component {

  render() {
    return (
      <AppTable
        model={BulkLoadModel}
        order={[
          {
            field: 'id',
            direction: 'desc'
          }
        ]}
        params={{
          limit: 10
        }}
        getColumnActions={(bulk, index) => {
          return (
            <div>
              <Link to={`/tools/bulk-load/details/${bulk.id}`} className="button-info">Details</Link>
            </div>
          )
        }}
      />
    );
  }
}
