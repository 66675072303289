import React, { useState } from 'react';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import Api from '@launchpad/logic/api/Api';
import { LoadingOverlay } from '@launchpad';

const OrderCardButton = props => {
  const [loading, setLoading] = useState(false);

  if (props.cards === null) return null;

  if (props.cards && props.cards.length > 0) return null;

  const orderCard = async (id: number, props: any = undefined) => {
    setLoading(true);

    try {
      const response: any = await Api.call('account/initialize', {
        id
      });

      if (response.success) {
        if (props.refresh) {
          props.refresh();
        }
      }
    } catch (e) {}

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <div className="main" style={{ margin: '10px 0' }}>
      {loading ? <LoadingOverlay /> : null}
      <div className="dark-blur" />
      <div
        className="customers-info text-center"
        style={{
          position: 'relative',
          padding: '10px 0',
          boxSizing: 'border-box'
        }}
      >
        <div>
          <p className="customer-name">
            The user doesn't have card yet. Would you like to order one?
          </p>
        </div>
        <div>
          <div className="bottom" style={{ alignItems: 'center' }}>
            <DeleteButton
              btnText="Order card"
              onConfirm={() => orderCard(props.user.id, props)}
              className="button-info"
              text="This action will create a card for the user. Are you sure?"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCardButton;
