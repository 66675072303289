import React from 'react';
import { AppInfoList } from '../../../../../components/widgets';

export default class recordRecordInfo extends React.Component {

  render() {

    let { record } = this.props;

    let details = [
      {
        label: 'Fail code',
        value: record.failCode || '-'
      },
      {
        label: 'Fail reason',
        value: record.failReason || '-'
      }
    ]
    return (
      <AppInfoList items={details}/>
    )
  }
}
