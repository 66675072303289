import React, { Component } from 'react';
import CustomLineChart from '../../comonents/CustomLineChart';
import AppTableFilter from '../../../../../components/crud/table/AppTableFilter';

class AppleBasicInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main">
        <div className="dark-blur" />

        <div className="customers-main">
          <div className="row">
            <div className="col">
              <div className="padded">
                <div className="row text-white">
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Apple Info in last 7 days</h4>
                    <hr />
                    <dl>
                      <dt>Installations:</dt>
                      <dd>140</dd>
                      <dt>Uninstallations:</dt>
                      <dd>25</dd>
                      <dt>Crashes:</dt>
                      <dd>100</dd>
                    </dl>
                  </div>
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Apple Info Total</h4>
                    <hr />
                    <dl>
                      <dt>Installations:</dt>
                      <dd>1400</dd>
                      <dt>Uninstallations:</dt>
                      <dd>250</dd>
                      <dt>Crashes:</dt>
                      <dd>1000</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppleBasicInfo;
