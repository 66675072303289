import React from 'react';
import ReactDOM from 'react-dom';
import ProsperAdmin from '@prosper/ProsperAdmin';
import * as serviceWorker from './serviceWorker';

const favicon: any = document.querySelector('link[rel="icon"]');

switch (process.env.REACT_APP_ADMIN_VARIANT) {
  case 'samsung':
    favicon.setAttribute('href', 'samsung-favicon.ico');
    document.title = 'SAMSUNG Rewards Administration';
    break;
  case 'prosper-rewards':
    favicon.setAttribute('href', 'prosper-rewards-favicon.ico');
    document.title = 'Prosper Loyalty Administration';
    break;
  case 'prosper2':
    favicon.setAttribute('href', 'prosper-favicon.png');
    document.title = 'Prosper² Administration';
    break;
  default:
    // favicon.setAttribute('href', 'favicon.ico');
    // document.title = 'BankVault';
    favicon.setAttribute('href', 'prosper-favicon.png');
    document.title = 'Prosper² Administration';
}

ReactDOM.render(<ProsperAdmin />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
