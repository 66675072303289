import React, { Component } from "react";
import { Link } from "react-router-dom";
import DeleteButton from "../../../components/crud/DeleteButton";
import { AppTable } from "../../../index";
import NotificationUserGroupModel from "../models/NotificationUserGroupModel";

export default class UserGroupsList extends Component {
  _delete(id) {
    new NotificationUserGroupModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true
        });
      });
  }

  render() {
    return (
      <div className="inner-single-col">
        <div className="customer-groups">
          <h1>
            <i className="fa fa-users" aria-hidden="true"></i> User Groups
          </h1>
          <AppTable
            title="User Groups"
            onInit={tableComponent => (this._tableComponent = tableComponent)}
            headerActions={
              <Link
                to="/user-groups/add"
                className="button width-240"
                style={{ marginRight: "10px" }}
              >
                <i className="fa fa-fw fa-plus"></i>Add new
              </Link>
            }
            filter={[
              {
                name: "term",
                type: "term"
              }
            ]}
            order={[
              {
                field: "id",
                direction: "desc"
              }
            ]}
            model={NotificationUserGroupModel}
            renderCell={(column, item, index) => {
              switch (column.name) {
                case "kycStatus":
                  return this._renderStatus(item);
              }
            }}
            getColumnActions={(userGroup, index) => {
              return (
                <div>
                  <Link
                    to={`/user-groups/edit/${userGroup.id}`}
                    className="button-info"
                  >
                    Edit
                  </Link>
                  <Link
                    to={`/user-groups/details/${userGroup.id}`}
                    className="button-info"
                  >
                    Details
                  </Link>
                  <DeleteButton
                    onConfirm={() => this._delete(userGroup.id)}
                    text={
                      'Are you sure you wish to delete user group "' +
                      userGroup.name +
                      '"?'
                    }
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
