import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BreadcrumbHelper from '../../../../util/BreadcrumbHelper';
import AppTabs from '../../../../components/crud/tabs/AppTabs';
import AndroidBasicInfo from './tabs/AndroidBasicInfo';
import AndroidInstall from './tabs/AndroidInstall';
import AndroidUninstall from './tabs/AndroidUninstall';
import AndroidCrashes from './tabs/AndroidCrashes';
import CompareAndroidApple from '../comonents/CompareAndroidApple';

class AnalyticsAndroid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'basic'
    };

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Android',
        link: '/analytics/android'
      }
    ]);
  }

  getTabs() {
    const basicInfo = <AndroidBasicInfo fetcher={this._fetcher} />;
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      },
      {
        id: 'installations',
        icon: 'mobile',
        title: 'Installations',
        component: <AndroidInstall {...this.props} />
      },
      {
        id: 'uninstallations',
        icon: 'ban',
        title: 'Uninstallations',
        component: <AndroidUninstall {...this.props} />
      },
      {
        id: 'crashes',
        icon: 'chain-broken',
        title: 'Crashes',
        component: <AndroidCrashes {...this.props} />
      },
      {
        id: 'compare',
        icon: 'files-o',
        title: 'Compare with Apple',
        component: <CompareAndroidApple {...this.props} />
      }
    ];
    return allTabs;
  }

  _renderContent() {
    return (
      <div style={{ marginTop: 20 }}>
        <AppTabs
          onTabChange={activeTab => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs()}
        />
      </div>
    );
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    return <div className="dashboard-management">{this._renderContent()}</div>;
  }
}
// Inject router
AnalyticsAndroid.contextTypes = {
  router: PropTypes.object
};

export default AnalyticsAndroid;
