import React from 'react';

export const variants = {
  prosper2: {
    logo: require('../../assets/img/Prosper2-logo.png'),
    cardImage: require('../../assets/img/Prosper-Card-Front.png'),
    loginText: () => (
      <h1>
        Welcome to Prosper²{' '}
        <span style={{ display: 'block', textAlign: 'center' }}>
          Administration
        </span>
      </h1>
    )
  },
  'prosper-rewards': {
    cardPresentations: true,
    logo: require('../../assets/variants/prosper-rewards/logo-white.png'),
    cardImage: require('../../assets/img/Prosper-Card-Front.png'),
    loginText: () => (
      <h1>
        Welcome to Prosper Loyalty{' '}
        <span style={{ display: 'block', textAlign: 'center' }}>
          Administration
        </span>
      </h1>
    )
  },
  samsung: {
    logo: require('../../assets/variants/samsung/Samsung-Rewards_logo.svg'),
    loginText: () => (
      <h1>
        Welcome to Samsung Rewards{' '}
        <span style={{ display: 'block', textAlign: 'center' }}>
          Administration
        </span>
      </h1>
    )
  }
};

const currentVariant = process.env.REACT_APP_ADMIN_VARIANT || 'prosper2';
export const CurrentVariant = variants[currentVariant];
