import React from 'react';

export default class BulkLoadRecordFields extends React.Component {

  render() {
    let { record } = this.props;

    let data = JSON.parse(record.data);
    let columns = [];

    Object.keys(data).forEach(x => {
      columns.push(
        <tr>
          <td>{x}</td>
          <td>{data[x]}</td>
        </tr>
      )
    });

    return (
      <table className="table">
        <tbody>
          <tr className="tr-dark-bg">
            <td>Field</td>
            <td>Value</td>
          </tr>
          {columns}
        </tbody>
      </table>
    )
  }
}
