import React from 'react';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import $ from 'jquery';
import DatetimepickerCSS from '../../styles/bootstrap/bootstrap-datetimepicker.css';
import _ from 'lodash';
import { AppIcon } from '@launchpad';

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: 'rgba(255, 255, 255, 1)' }
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #ffffff!important'
        },
        '&:after': {
          borderBottom: '2px solid #0275d8!important'
        }
      },
      root: {
        // Name of the rule
        color: 'white' // Some CSS
      }
    }
  }
});
export default class InputField extends React.Component {
  constructor() {
    super();
    this.state = {
      fieldType: this.props.type || 'text',
      showPassword: false
    };
  }

  componentDidMount() {
    if ($(this._input).hasClass('datepicker')) {
      $(this._input).each((index, element) => {
        $(element)
          .datetimepicker({
            useCurrent: false,
            format: 'DD/MM/YYYY'
          })
          .on('dp.change', e => {
            let value = e.date.format ? e.date.format('DD/MM/YYYY') : null;
            setTimeout(() => {
              $(element)
                .data('DateTimePicker')
                .hide();
              this.props.handler.handleInputChange(this.props.name, value);
            }, 50);
          });
      });
    }
    this.setState({
      fieldType: this.props.type
    });
  }
  /**
   * Get class name
   *
   * @returns {string}
   */
  getClassName() {
    let classNames = ['input-group'];
    if (this.props.fullWidth) {
      classNames.push('fulled');
    }

    return classNames.join(' ');
  }

  _getInputClassName() {
    let classNames = [];

    let type = this.props.type;

    switch (type) {
      case 'date':
        classNames.push('datepicker');
        break;
    }

    return classNames.join(' ');
  }

  /**
   * Handle input change
   *
   * @param e
   */
  handleInputChange(e) {
    if (this.props.handler) {
      this.props.handler.handleInputChange(e);
    }

    this.props.onChange(e);
  }
  getValidationError() {
    let messages = [];

    if (!this.props.handler) {
      return null;
    }

    this.props.handler
      .getErrors()
      .filter(x => x.field == this.props.name)
      .forEach(error => messages.push(error.message));
    if (messages.length == 0) {
      return null;
    }
    return (
      <div className="validation-message" key={this.props.name + '-error'}>
        {messages.join('<br/>')}
      </div>
    );
  }

  _getType() {
    let type = this.props.type;

    if (!type) {
      type = 'text';
    }

    if (type == 'date') {
      type = 'text';
    }

    if (type == 'password') {
      type = this.state.fieldType;
    }

    return type;
  }

  _getNote() {
    if (!this.props.note) return null;

    return (
      <em style={{ position: 'absolute', right: 0, bottom: -20 }}>
        {this.props.note}
      </em>
    );
  }

  toggleShowPassword() {
    this.setState(
      {
        showPassword: !this.state.showPassword
      },
      () => {
        if (this.state.showPassword) {
          this.setState({
            fieldType: 'text'
          });
        } else {
          this.setState({
            fieldType: 'password'
          });
        }
      }
    );
  }

  /**
   * Render input
   *
   * @returns {XML}
   */
  render() {
    let inputProps = _.get(this.props.materialProps, 'inputProps', {});

    let className = _.get(inputProps, 'className', '');

    className += ' ' + this._getInputClassName();

    inputProps.className = className;

    inputProps.ref = ref => (this._input = ref);

    let materialProps = {
      ...this.props.materialProps,
      inputProps
    };

    let containerClassName = this.getClassName();

    let type = this._getType();

    let validationError = this.getValidationError();
    return (
      <div className={containerClassName}>
        <MuiThemeProvider theme={theme}>
          <TextField
            ref="actualInput"
            autoComplete={this.props.autoComplete}
            label={this.props.label}
            type={type}
            name={this.props.name}
            inputProps={this.props.inputProps}
            value={this.props.value}
            multiline={type == 'textarea'}
            onChange={this.handleInputChange.bind(this)}
            {...materialProps}
          />
          {this._getNote()}
        </MuiThemeProvider>
        {this.props.type === 'password' && (
          <AppIcon
            onClick={() => this.toggleShowPassword()}
            name={!this.state.showPassword ? 'fa.eye' : 'fa.eye-slash'}
            style={{
              fontSize: 18,
              color: 'white',
              position: 'absolute',
              top: '42%',
              right: '15px',
              cursor: 'pointer'
            }}
          />
        )}
        {validationError}
      </div>
    );
  }
}

InputField.defaultProps = {
  onChange: e => {}
};
