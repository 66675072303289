import React from "react";
import { AppPage, AppWidget } from "../../../components";
import { AppFetcher } from "../../../index";
import BreadcrumbHelper from "../../../util/BreadcrumbHelper";
import NotificationUserGroupModel from "../models/NotificationUserGroupModel";
import GroupInfo from "./components/GroupInfo";
import GroupUsers from "./components/GroupUsers";
import GroupFilters from "./components/GroupFilters";
import { Link } from "react-router-dom";

export default class UserGroupDetails extends React.Component {
  constructor(props) {
    super(props);

    BreadcrumbHelper.setCrumbs([
      {
        label: "User Groups",
        link: "/user-groups"
      }
    ]);
  }

  _renderContent(item) {
    if (!this.firstLoad) {
      BreadcrumbHelper.addCrumb(item.name);
      this.firstLoad = true;
    }

    return (
      <AppPage title={"Group Details"} icon={"info"}>
        <div className="row">
          <div className="col-sm-4">
            <AppWidget
              title={item.name}
              icon={null}
              padded
              headerActions={() => (
                <Link
                  className="button button-info"
                  to={"/user-groups/edit/" + item.id}
                >
                  Edit
                </Link>
              )}
            >
              <GroupInfo group={item} />
            </AppWidget>
            <AppWidget title={"Filters"} icon={null} padded>
              <GroupFilters group={item} />
            </AppWidget>
          </div>

          <div className="col-sm-8">
            <AppWidget title="Group Users" icon={"list"}>
              <GroupUsers group={item} />
            </AppWidget>
          </div>
        </div>
      </AppPage>
    );
  }
  render() {
    return (
      <AppFetcher
        ref={fetcher => (this._fetcher = fetcher)}
        model={NotificationUserGroupModel}
        id={this.props.match.params.id}
        renderContent={record => this._renderContent(record)}
      ></AppFetcher>
    );
  }
}
