import React from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux';
import Config from '../../../../../config';
import ProgressBarComponent from '../../../../../components/crud/ProgressBarComponent';
import AppValidationErrors from '../../../../../components/crud/AppValidationErrors';
import AppButton from '../../../../../components/crud/AppButton';
import ToastHelper from '../../../../../util/ToastHelper';

const STATUS_OLD = 'old';
const STATUS_NEW = 'new';
const STATUS_UPLOADING = 'uploading';
const STATUS_QUEUED = 'queued';
const STATUS_REMOVED = 'removed';

const extractName = value => {
  return value.split('/').pop();
};

const mapStateToProps = state => {
  return {
    token: state.auth.token
  };
};

export class AppleReportsUploadField extends React.Component {
  state = {
    files: []
  };

  constructor(props) {
    super(props);

    if (props.value) {
      this.state = {
        initialValue: null,
        files: [
          {
            id: _.uniqueId('prefix-'),
            file: props.value,
            name: extractName(props.value),
            status: STATUS_OLD,
            progress: 0,
            value: props.value
          }
        ]
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    const stateChange = {};

    if (props.value && !state.initialValue) {
      stateChange.initialValue = props.value;
      stateChange.files = [
        {
          id: _.uniqueId('prefix-'),
          file: props.value,
          name: extractName(props.value),
          status: STATUS_OLD,
          progress: 0,
          value: props.value
        }
      ];
    }

    return stateChange;
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleDrop = acceptedFiles => {
    this._addFiles(acceptedFiles);
  };

  _addFiles(acceptedFiles) {
    const parsedFiles = [];

    acceptedFiles.forEach(file => {
      parsedFiles.push({
        id: _.uniqueId('prefix-'),
        file,
        name: file.name,
        status: STATUS_QUEUED,
        progress: 0,
        value: null
      });
    });
    if (!this.props.multiple) {
      this.setState(
        {
          files: [parsedFiles[0]]
        }
        //   ,
        //   () => this._triggerUpload()
      );
    } else {
      this.setState(
        {
          files: [...this.state.files, ...parsedFiles]
        }
        // ,
        // () =>
        // this._triggerUpload()
      );
    }
  }

  _triggerUpload() {
    if (this.state.files.length === 0) {
      ToastHelper.show('Please select file!');
      return;
    }
    this.state.files.forEach(file => {
      // if (file.status === STATUS_QUEUED) {
      this._startUploading(file);
      // }
    });
  }

  async _startUploading(file) {
    const data = new FormData();
    data.append('file', file.file);
    data.append('filename', file.name);

    this._updateFile(file, {
      status: STATUS_UPLOADING
    });

    axios
      .post(`${Config.url}stats/stores/apple/upload`, data, {
        headers: {
          Authorization: `Bearer ${this.props.token}`
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this._updateFile(file, {
            progress: percentCompleted
          });
        }
      })
      .then(response => {
        if (response.data.success) {
          this._updateFile(file, {
            value: this.state.files.name,
            status: STATUS_NEW
          });
          this.props.close();
          ToastHelper.success(
            'You have successfully added the Apple Reports file.'
          );
        }
      })
      .catch(error => {
        this._removeFile(file);
      });
  }

  _updateFile(file, data) {
    const files = [...this.state.files];

    const foundFile = files.find(x => x.id === file.id);

    if (foundFile) {
      const index = files.indexOf(foundFile);
      const newFile = {
        ...foundFile,
        ...data
      };
      files[index] = newFile;
      this.setState(
        {
          files
        },
        () => this._afterFilesUpdate()
      );
    }
  }

  _removeFile(file) {
    const files = [...this.state.files];
    const index = files.indexOf(file);
    if (index >= 0) {
      files.splice(index, 1);
      this.setState(
        {
          files
        },
        () => {
          this._afterFilesUpdate();
        }
      );
    }
  }

  _afterFilesUpdate() {
    if (this.props.handler) {
      if (!this.props.multiple) {
        this.props.handler.handleInputChange(
          this.props.name,
          this.state.files[0] ? this.state.files[0].value : null
        );
      }
    }
  }

  _getDropzone() {
    if (!this.props.multiple && this.state.files.length > 0) return null;

    return (
      <Dropzone
        accept={this.props.accept}
        onDrop={this.handleDrop}
        multiple={this.props.multiple}
        style={{
          display: 'inline-block',
          padding: 20,
          textAlign: 'center',
          border: '2px dashed #fff',
          borderRadius: 10
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <div
              style={{
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}
              className="uploadButton"
            >
              <button
                type="file"
                value="upload"
                className="cancelBtn"
                style={{
                  color: 'white',
                  background: 'transparent',
                  opacity: 0
                }}
              >
                {' '}
              </button>
              <p>BROWSE</p>
              <input
                type="file"
                id="apple_file"
                name="apple_file"
                title=" "
                accept={this.props.accept}
                onChange={this.onImageChange.bind(this)}
                style={{ opacity: 0 }}
                className="fileInput-addnewproject"
                {...getInputProps()}
              />
              <div style={{ color: '#fff', marginTop: 20 }}>
                <div>
                  <em
                    className="fa fa-fw fa-upload"
                    style={{ fontSize: '4em' }}
                  />
                </div>
                <div>
                  <span>or</span>
                </div>
                <div>
                  <span>drag n' drop here</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  _getUploadedFiles() {
    if (!this.state.files.length) return null;

    const elements = [];

    const UploadedFile = props => this._getUploadedFileItem(props.file);

    this.state.files.forEach((file, index) => {
      elements.push(<UploadedFile file={file} key={index} />);
    });

    return elements;
  }

  _getUploadedFileItem(file) {
    let bottomElement = null;

    if (file.status === STATUS_UPLOADING) {
      bottomElement = (
        <div style={{ marginTop: 10 }}>
          <ProgressBarComponent percentage={file.progress} />
        </div>
      );
    } else {
      bottomElement = (
        <div style={{ marginTop: 10 }}>
          <a href="javascript:void(0)" onClick={() => this._removeFile(file)}>
            Remove
          </a>
        </div>
      );
    }

    return (
      <div
        className="file-preview"
        style={{
          display: 'inline-block',
          border: '1px solid #e0e0e0',
          padding: 10,
          textAlign: 'center',
          width: '100%',
          maxWidth: 520,
          marginTop: 30
        }}
      >
        <p style={{ margin: 0 }}>{file.name}</p>

        {bottomElement}
      </div>
    );
  }

  render() {
    const dropzone = this._getDropzone();
    const uploadedFiles = this._getUploadedFiles();
    return (
      <div className="apple-upload-btn-holder">
        <label style={{ color: 'white' }}>{this.props.label}</label>
        <div style={{ marginBottom: 30, width: '100%', textAlign: 'center' }}>
          {dropzone}
          {uploadedFiles}
        </div>
        <AppButton type="button" onClick={() => this._triggerUpload()}>
          Upload
        </AppButton>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AppleReportsUploadField);

AppleReportsUploadField.defaultProps = {
  label: '*Upload Apple Reports file',
  accept: '.csv',
  multiple: false
};
