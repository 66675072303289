import BaseModel from '../../../logic/model/BaseModel';

export default class LoginModel extends BaseModel {

    /**
     * Get submit url
     */
    getSubmitUrl() {
        return 'member/login';
    }

    /**
     * Get validation rules
     */
    getValidationRules() {
      return {
        email: {
          presence: {
            message: '^Please enter an email address'
          },
          email: {
            message: '^Please enter a valid email address'
          }
        },

        password: {
          presence: {
            message: '^Please enter a password'
          },
          length: {
            minimum: 5,
            message: '^Your password must be at least 5 characters long'
          }
        }
      }
    }
}
