import React from 'react';
import { Route } from 'react-router-dom';
import ProsperCardPreview from './components/ProsperCardPreview';
import OrderCardButton from './components/OrderCardButton';
import pages from './pages';
import LoadReport from './pages/load-report/LoadReport';
import BulkLoad from '../../../@launchpad/modules/tools/bulk-load';
import { CurrentVariant } from './variants';
import FxNotificationsReports from './components/FxNotificationsReports';
import CardNotifications from './components/CardNotifications';

const overrides = {
  login: {
    page: {
      logo: () => (
        <img className="logo" src={CurrentVariant.logo} alt="prosper-logo" />
      ),
      text: CurrentVariant.loginText
    }
  },
  sidemenu: {
    logo: () => (
      <div className="dash-logo">
        <img src={CurrentVariant.logo} alt="logo" />
      </div>
    )
  },
  card: {
    cardPresentations: CurrentVariant.cardPresentations,
    image: CurrentVariant.cardImage,
    preview: (props: any) => <ProsperCardPreview {...props} />,
    notifications: (props: any) => <CardNotifications {...props} />,
    points: (props: any) => (
      <>
        <dt>Prosper points:</dt>
        <dd>{props.card.userPoints || '-'}</dd>
      </>
    )
  },
  user: {
    details: {
      beforeContent: (props: any) => <OrderCardButton {...props} />,
      orderCard: (props: any) => <OrderCardButton {...props} />
    }
  },
  pages,
  tools: {
    route: () => (
      <>
        <Route path="/tools/bulk-load" component={BulkLoad} />
        <Route path="/tools/load-report" component={LoadReport} />
      </>
    )
  },
  'fx-notifications-reports': (props: any) => (
    <FxNotificationsReports user={props.user} />
  ),
  'show-download-statements-btn': true,
  'identify-user-widget': () => null
};

export default overrides;
