import React from 'react';
import { AppTable } from '../../../../components';
import BulkLoadRecrodModel from '../models/BulkLoadRecordModel';
import { Link } from 'react-router-dom';
import { AppBadge } from '../../../../components';
import NumberHelper from '../../../../util/NumberHelper';

export default class BulkLoadRecords extends React.Component {

  render() {
    let bulkLoad = this.props.bulkLoad;

    return (
      <AppTable
        parseUrl={false}
        model={BulkLoadRecrodModel}
        onInit={ref => { this._ref = ref; this.props.onInit(ref); }}
        params={{
          bulkLoad: bulkLoad.id
        }}
        renderCell={(column, item, index) => {
          switch(column.name) {
            case 'status':
              return <AppBadge text={item.status}/>
            case 'amount':
              return NumberHelper.currency(item.amount, item.currency);
          }
        }}
        getColumnActions={(item, index) => {
          return (
            <div>
              <Link to={`/tools/bulk-load/details/${bulkLoad.id}/record/${item.id}`} className="button-info">Details</Link>
            </div>
          )
        }}
      />
    )
  }
}


BulkLoadRecords.defaultProps = {
  onInit: () => {}
}
