import React, { Component } from 'react';
import ModalDialog from '../../components/modal/ModalDialog';

export default class DeleteButton extends Component {
  constructor () {
    super();
    this.state = {
      showDeleteModal: false
    };
  }

  confirm() {
    this.setState({
      showDeleteModal: true
    })
  }

  onClose() {
    this.setState({
      showDeleteModal: false
    })
  }

  onConfirm() {
    this.props.onConfirm();
    this.onClose();
  }

  onReject() {
    this.props.onReject();
    this.onClose();
  }

  render() {
    let classes = this.props.className ? [this.props.className] : ['button-danger'];

    if(this.props.small) {
      classes.push('button-small');
    }
    if(this.props.block) {
      classes.push('btn-block');
    }

    return(
      <span>
        <ModalDialog show={this.state.showDeleteModal} onClose={this.onClose.bind(this)}>
          <p>{this.props.text}</p>
          <div className="react-modal-footer">
            <a href="javascript:void(0)" className="button" onClick={this.onReject.bind(this)}>No</a>
            <a href="javascript:void(0)" className="button-danger" onClick={this.onConfirm.bind(this)}>Yes</a>
          </div>
        </ModalDialog>
        <a className={classes.join(' ')} onClick={this.confirm.bind(this)}>{this.props.btnText}</a>
      </span>
    );
  }
}


DeleteButton.defaultProps = {
  text: 'Are you sure you wish to delete this item?',
  btnText: 'Delete',
  onReject: () => {},
  onConfirm: () => {}
}
