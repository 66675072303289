import React from 'react';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { OverrideService } from './logic/services';
import store from './logic/store/AppStore';
import AppToastAlert from './components/elements/AppToastAlert';
import LandingScreen from './LandingScreen';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.scss';
import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';

Modal.setAppElement('#root');

export interface LaunchPadAdminProps {
  configuration: any;
}

export default class LaunchPadAdmin extends React.Component<any, any> {
  constructor(props) {
    super(props);
    OverrideService.storeConfiguration(props.configuration);
  }

  render() {
    // if (!OverrideService.configuration) return null;
    return (
      <Provider store={store}>
        <LandingScreen />
        <AppToastAlert />
      </Provider>
    );
  }
}
