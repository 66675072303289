import React from 'react';

export default class AppTabs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: null
    }
  }

  static getDerivedStateFromProps(props, state) {
    let stateUpdate = {};

    if(props.activeTab && !state.activeTab) {
      stateUpdate.activeTab = props.activeTab;
    }

    return stateUpdate;
  }

  /**
   * Called on tab click
   * @param {Object} tab Clicked tab
   */
  tabClick(tab) {
    this.setState({
      activeTab: tab.id
    }, () => {
      this.props.onTabChange(this.state.activeTab)
    })
  }

  /**
   * Render
   */
  render() {
    let tabs = this.props.tabs;
    let activeTab = this.state.activeTab ? tabs.find(x => x.id == this.state.activeTab) : tabs[0];

    // Get active
    let tabsElements = this.tabElements || [];
    tabs.map((tab, index) => {
      let tabElement = (
        <li key={'tab' + index} className={activeTab.id === tab.id ? 'active' : ''}>
          <a href="javascript:void(0)" onClick={() => this.tabClick(tab)}><i className={'fa fa-fw fa-' + tab.icon}></i> {tab.title}</a>
        </li>
      );
      tabsElements.push(tabElement);
    });
    this.tabElements = this.tabElements;

    return (
      <div>
        <nav>
          <ul className="dashboard-management-nav customers-dashboard-nav">
            {tabsElements}
          </ul>
        </nav>
        {activeTab.component}
      </div>
    )
  }
}


AppTabs.defaultProps = {
  onTabChange: () => {}
}
