import axios from 'axios';
import ToastHelper from '@launchpad/util/ToastHelper';
import Logger from '../../util/Logger';
import store from '../store/AppStore';
import Config from '../../config';
import appStore from '../store/AppStore';

export default class Api {
  static getBaseUrl() {
    if (Config.env === 'live') {
      return Config.url;
    }
    return Config.url;
  }

  static call(
    method: string | number,
    data: any = null,
    onProgress: any = null
  ) {
    const authToken = store.getState().auth.token || null;

    const requestUrl = this.getBaseUrl() + method;
    const ajaxOptions: any = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      contentType: 'application/json',
      body: JSON.stringify(data)
    };

    if (authToken !== null) {
      ajaxOptions.headers.Authorization = `Bearer ${authToken}`;
    }
    Logger.log('api options', requestUrl, ajaxOptions);
    Logger.log('api request', requestUrl, data);

    return new Promise((resolve, reject) => {
      // const config =  {
      //   onUploadProgress: function(progressEvent: any) {
      //     var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      //   }
      // }

      axios({
        url: this.getBaseUrl() + method,
        method: 'POST',
        // contentType: 'application/json',
        // withCredentials: true,
        headers: ajaxOptions.headers,
        data: JSON.stringify(data),
        onUploadProgress(progressEvent: any) {
          if (onProgress) {
            onProgress(progressEvent);
          }
        }
      })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error.response) {
            Logger.log('data', error.response);

            if (error.response.status === 403) {
              localStorage.removeItem('AUTH_TOKEN');
              window.location.replace('/login');
              // store.dispatch(logoutTriggerAction());
            }

            if (error.response.status === 200) {
              const { code } = error.response.data;

              if (code === 401 || code === 403) {
                localStorage.removeItem('AUTH_TOKEN');
                window.location.replace('/login');
                // store.dispatch(logoutTriggerAction())
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
          reject(error.response ? error.response.data : error);
        });
    });
  }

  static async guardedDownload(url) {
    const response: any = await this.call('tools/download/trigger', {
      file: url
    });

    if (response.data.url) {
      window.location = response.data.url;
    }
  }

  /**
   * Download file
   */
  static downloadFile(
    url: any,
    params = {},
    errorMessage: string | undefined,
    onDownloadStart = () => null,
    onDownloadEnd = () => null
  ) {
    const headers = {} as any;
    const { token } = appStore.getState().auth;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    if (onDownloadStart) {
      onDownloadStart();
    }

    axios({
      url: this.getBaseUrl() + url,
      method: 'POST',
      headers,
      responseType: 'blob',
      data: JSON.stringify(params)
    })
      .then((response: any) => {
        const disposition = response.headers['content-disposition'];
        const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
        const linkUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = linkUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        if (response.status === 200) {
          ToastHelper.success('Successfully downloaded file.');
        } else {
          ToastHelper.show('Something is wrong. Please try again later.');
        }
        if (!response.success) {
          ToastHelper.show(response.message);
        }
      })
      .catch(() => {
        if (errorMessage) {
          ToastHelper.show(errorMessage);
        } else {
          ToastHelper.show('Something is wrong. Please try again later.');
        }
      })
      .finally(() => {
        if (onDownloadEnd) {
          onDownloadEnd();
        }
      });
  }

  /**
   * Upload file
   */
  static async uploadFile(url, file) {
    const formData = new FormData();
    formData.append('importFile', file);

    const headers = {
      'Content-Type': 'multipart/form-data'
    } as any;
    const { token } = appStore.getState().auth;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios.post(this.getBaseUrl() + url, formData, {
      headers
    });

    return response.data && response.data.success;
  }
}
