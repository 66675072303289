import React from 'react';
import { AppPage, AppWidget } from '../../../../components';
import LatestBulkLoads from '../components/LatestBulkLoads';
import NewBulkLoad from '../components/NewBulkLoad';

export default class BulkLoadDashboard extends React.Component {

  render() {
    return (
      <AppPage title={'Bulk Load'} icon={'plus'}>
        <div className='row'>
          <div className='col-sm-4'>
            <AppWidget title='New Load' icon={'plus'}>
              <NewBulkLoad/>
            </AppWidget>
          </div>

          <div className='col-sm-8'>
            <AppWidget title='Previous bulk loads' icon={'list'}>
              <LatestBulkLoads/>
            </AppWidget>
          </div>
        </div>
      </AppPage>
    );
  }
}
