import React, { Component } from "react";
import NotificationUserGroupModel from "../models/NotificationUserGroupModel";
import UserGroupForm from "./components/UserGroupForm";
import { AppPage, AppWidget } from "../../../components";
import { AppFetcher } from "../../../index";

export default class UserGroupEdit extends Component {
  render() {
    return (
      <AppPage title={"Edit group"} icon={"pencil"}>
        <AppFetcher
          model={NotificationUserGroupModel}
          id={this.props.match.params.id}
          renderContent={record => (
            <AppWidget title={record.name} icon={null}>
              <UserGroupForm record={record} />
            </AppWidget>
          )}
        ></AppFetcher>
      </AppPage>
    );
  }
}
