import React from 'react';
import { AppInfoList } from '../../../../../components/widgets';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppBadge } from '../../../../../components';
import NumberHelper from '../../../../../util/NumberHelper';

export default class recordRecordInfo extends React.Component {

  render() {

    let { record } = this.props;

    let details = [
      {
        label: 'Token',
        value: record.token
      },
      {
        label: 'Card',
        value: record.card ? <Link to={'/cards/details/' + record.card.id}>{record.card.pan}</Link> : '-'
      },
      {
        label: 'User',
        value: record.user ?  <Link to={'/users/all-users/details/' + record.user.id}>{record.user.fullName}</Link> : '-'
      },
      {
        label: 'Amount',
        value: NumberHelper.currency(record.amount, record.currency)
      },
      {
        label: 'Status',
        value: <AppBadge text={record.status}/>
      }
    ]
    return (
      <AppInfoList items={details}/>
    )
  }
}
