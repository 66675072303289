import AppStore from '../store/AppStore';
import { showModal, hideModal } from '../../modules/modals/ModalActions';
import RoutingService from './RoutingService';

const ModalService = {

  showModal(id, props = {}, closeOther = false) {
    RoutingService.preventScroll();
    if(closeOther) {
      ModalService.hideModal();
    }
    AppStore.dispatch(showModal(id, props));

  },

  hideModal(id, props = {}) {
    RoutingService.preventScroll();
    AppStore.dispatch(hideModal(id, props));
  },

  info(text, closeable = true) {
    AppStore.dispatch(showModal('INFO_MODAL', {
      text,
      closeable
    }));
  }
}

export default ModalService;
