import BaseModel from './BaseModel';

export default class ConfigModel extends BaseModel {

  /**
   * Get list url
   */
  getListUrl() {
    return 'app/config';
  }

  /**
   * Load configuration, first from db, then from API
   */
  async load() {
    try {
      // Load from network
      await this.loadFromNetwork();
    } catch (e) {
      // Logger.log('Config Load Error', e);
    }
  }

  /**
   * Load from network
   */
  async loadFromNetwork() {
    // Get from network
    let result = await (new this()).fetch();

    if (result) {

      // Merge
      this.merge(result);
    }
  }

}
