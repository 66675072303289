import React from 'react';

export default class AppIcon extends React.Component {

  render() {

    let icon = null;

    if(this.props.icon) {
      icon = <i className={'fa fa-' + this.props.icon}></i>
    }

    return icon;
  }
}
