import React from 'react';
import Api from '../../../../../logic/api/Api';
import { LoadingOverlay } from '../../../../../index';
import AnaliticsAndroidModel from '../models/AnalyticsAndroidModel';

class AndroidBasicInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      installs: {},
      uninstalls: {},
      crashes: {}
    };
  }

  componentDidMount() {
    this._loadWidget();
  }

  async _loadWidget() {
    // TODO: Load widget here
    this.setState({
      loading: true
    });

    try {
      const result = await new AnaliticsAndroidModel().getOverviewData();
      this.setState({
        // ...result,
        installs: { ...result.Installs },
        uninstalls: { ...result.Uninstalls },
        crashes: { ...result.Crashes },
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    const { installs, uninstalls, crashes } = this.state;

    return (
      <div className="main">
        <div className="dark-blur" />

        <div className="customers-main">
          {this.state.loading ? <LoadingOverlay /> : null}

          <div className="row">
            <div className="col">
              <div className="padded">
                <div className="row text-white">
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Android Info Total</h4>
                    <hr />
                    <dl>
                      <dt>Installations:</dt>
                      <dd>{installs.totalGoogleInstalls}</dd>
                      <dt>Uninstallations:</dt>
                      <dd>{uninstalls.totalGoogleUninstalls}</dd>
                      <dt>Crashes:</dt>
                      <dd>{crashes.totalGoogleCrashes}</dd>
                    </dl>
                  </div>
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Android Info in last year</h4>
                    <hr />
                    <dl>
                      <dt>Installations:</dt>
                      <dd>{installs.googleInstallsInLastYear}</dd>
                      <dt>Uninstallations:</dt>
                      <dd>{uninstalls.googleUninstallsInLastYear}</dd>
                      <dt>Crashes:</dt>
                      <dd>{crashes.googleCrashesInLastYear}</dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="padded">
                <div className="row text-white">
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Android Info in last month</h4>
                    <hr />
                    <dl>
                      <dt>Installations:</dt>
                      <dd>{installs.googleInstallsInLastMonth}</dd>
                      <dt>Uninstallations:</dt>
                      <dd>{uninstalls.googleUninstallsInLastMonth}</dd>
                      <dt>Crashes:</dt>
                      <dd>{crashes.googleCrashesInLastMonth}</dd>
                    </dl>
                  </div>
                  <div style={{ marginBottom: 20 }} className="col-lg-6">
                    <h4>Android Info in last week</h4>
                    <hr />
                    <dl>
                      <dt>Installations:</dt>
                      <dd>{installs.googleInstallsInLastWeek}</dd>
                      <dt>Uninstallations:</dt>
                      <dd>{uninstalls.googleUninstallsInLastWeek}</dd>
                      <dt>Crashes:</dt>
                      <dd>{crashes.googleCrashesInLastWeek}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AndroidBasicInfo;
