import { toast } from 'react-toastify';

export default class ToastHelper {
  static alertElement = null;

  static toastId = null;

  static setAlert(alertElement) {
    this.alertElement = alertElement;
  }

  static success(text) {
    this.show(text, toast.TYPE.SUCCESS);
  }

  static show(text, type = toast.TYPE.ERROR) {
    const options = {
      type,
      hideProgressBar: false,
      position: toast.POSITION.TOP_RIGHT,
      pauseOnHover: true,
      autoClose: 3000,
      closeOnClick: true,
      draggable: true
    };

    if (!toast.isActive(this.toastId)) {
      this.toastId = toast(text, options);
    }
  }

  static close() {
    toast.dismiss();
  }
}
