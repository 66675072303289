import React, { Component } from 'react';
import SideMenuItem from './SideMenuItem';
import $ from 'jquery';
import { LPOverridable } from '../../../index';
import { OverrideService } from '../../../logic/services';
import ModuleService from '../../../logic/module/ModuleService';
import { withRouter } from 'react-router';

export class SideBar extends Component<any, any> {
  modules: any[];
  moduleService: ModuleService;

  constructor(props: any) {
    super(props);

    this.moduleService = new ModuleService();
    this.modules = this.moduleService.getModules();

    this.state = {
      navigation: this.getNavigation()
    };
  }

  componentDidMount() {
    if ($(window).width() < 1440) {
      this.closeSideMenu();
      $('#collapse').css({ display: 'block', 'margin-top': '12px' });
      $('.arrow').hide();
    }
    if ($('.dashboard-side').hasClass('collapsed')) {
      $('.arrow').hide();
    } else {
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    }

    let toggleMenu = function() {
      let el = $('.sideMenuList')
        .find('> li')
        .find('> a');
      el.click(function(this: typeof el) {
        let elm = $(this);
        let parentLi = elm.closest('li');

        parentLi
          .closest('ul')
          .children()
          .not(parentLi)
          .each(function(index: any, li: any) {
            let $li = $(li);
            $li.find('.sub-nav').slideUp();
            $li.find('.arrow').removeClass('arrow-down');
          });

        // console.error(parentLi);

        elm
          .parent()
          .find('.sub-nav')
          .slideToggle();
        elm
          .parent()
          .find('.arrow')
          .toggleClass('arrow-down');
      });
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    };
    toggleMenu();
  }

  _getFirstRoute() {
    if (this.modules.length == 0) return null;

    return this._getFirstPath(this.modules[0].getNavigation());
  }

  _getFirstPath(navigation) {
    for (let i = 0; i < navigation.length; i++) {
      let item = navigation[i];
      if (item.path) return item.path;
      if (item.children) return this._getFirstPath(item.children);
    }

    return null;
  }

  showSideMenu() {
    $('.dashboard-side').removeClass('collapsed');
    $('.dashboard-main').removeClass('full-width');
    $('.dashboard-menu-top').removeClass('fullsize');
    $('.breadcrumb').removeClass('small');
    $('.collapse-menu').show();
    $('#collapse').hide();
    $('.sub-nav').css({ display: 'none' });
    $('.sub-nav').removeClass('toggled-menu');
    $('.sideMenuList li a span').css({ display: 'inline-block' });
    // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
  }

  closeSideMenu() {
    $('.dashboard-side').addClass('collapsed');
    $('.dashboard-main').addClass('full-width');
    $('.dashboard-menu-top').addClass('fullsize');
    $('.breadcrumb').addClass('small');
    $('.collapse-menu').hide();
    $('#collapse').show();
    $('.sub-nav').css({ display: 'none' });
    $('.sideMenuList li a span').css({ display: 'none' });
    $('#collapse').css({ position: 'relative', height: '40px' });
    $('.sub-nav').addClass('toggled-menu');
    $('.arrow').hide();
    $('.dashboard-main ').click(function() {
      $('.toggled-menu').css({ display: 'none' });
    });
    $('.toggled-menu li').click(function() {
      $('.toggled-menu').css({ display: 'none' });
    });
    // let sideMenu = function () {
    //   let el = $('.sideMenuList li');
    //   el.hover(function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').addClass('visible');
    //   }, function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').removeClass('visible');
    //   });
    // }
    // sideMenu();
  }

  getNavigation() {
    let navigationItems = this._getNavigationItems();

    let permissionedNavigationItems = this._filterNavigationItemsPerPermission(
      navigationItems
    );

    return permissionedNavigationItems;
  }

  _getNavigationItems() {
    return OverrideService.getSideNavigation(this._getItems());
  }

  _filterNavigationItemsPerPermission(items) {
    return items;
  }

  _getItems() {
    return this.moduleService.getNavigation();
  }

  render() {
    const items = [] as any;
    this.state.navigation.forEach(
      (
        item: JSX.IntrinsicAttributes &
          JSX.IntrinsicClassAttributes<SideMenuItem> &
          Readonly<any> &
          Readonly<{ children?: React.ReactNode }>,
        index: string
      ) => {
        const key = `side-menu-item-${index}`;
        items.push(<SideMenuItem {...item} key={key} />);
      }
    );
    return (
      <div className="dashboard-side">
        <LPOverridable name={'sidemenu.logo'}>
          <div className="dash-logo">
            <img
              src={require('../../../assets/Launchpad_logoWhite.png')}
              alt=""
            />
          </div>
        </LPOverridable>
        <div className="side-menu">
          <i
            id="collapse"
            onClick={this.showSideMenu}
            style={{
              color: '#fff'
            }}
            className="fa fa-bars"
            aria-hidden="true"
          ></i>

          <p className="collapse-menu" onClick={this.closeSideMenu}>
            <i
              style={{
                color: '#fff'
              }}
              className="fa fa-angle-double-left"
              aria-hidden="true"
            ></i>
            Collapse menu
          </p>

          <ul className="sideMenuList">{items}</ul>
        </div>
      </div>
    );
  }
}

export default withRouter(SideBar);
