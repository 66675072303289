import React from 'react';
import { CircularProgress } from '@material-ui/core';

const AppSpinner: React.FC<any> = props => {
  return <CircularProgress
    size={100}
    style={{ marginBottom: 20 }}
    className={'circle-progress ' + props.className}
  />;
}

export default AppSpinner;
