import BaseModel from '../../../../../logic/model/BaseModel';
import Api from '../../../../../logic/api/Api';

export default class AnaliticsAndroidModel extends BaseModel {
  async getOverviewData() {
    const result = await Api.call('stats/stores/google/overview');
    return result.data;
  }

  async getChartInstallData() {
    const result = await Api.call('stats/stores/google/installs');
    return result.data;
  }
}
