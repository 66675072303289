import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../../logic/store/AppStore';


export default class AppRouter extends React.Component {

  render() {
    return (
      <ConnectedRouter history={history}>
        {this.props.children}
      </ConnectedRouter>
    );
  }
}
