import React from "react";
import { AppInfoList } from "../../../../components/widgets";

export default class GroupFilters extends React.Component {
  render() {
    let { group } = this.props;
    let { filter } = group;

    let details = [
      {
        label: "Filter type",
        value:
          group.filterType == "conditions" ? "Custom filter" : "Specific users"
      }
    ];

    if (filter.createdFrom) {
      details.push({
        label: "Registered from",
        value: filter.createdFrom
      });
    }

    if (filter.createdTo) {
      details.push({
        label: "Registered to",
        value: filter.createdTo
      });
    }

    if (filter.registeredBefore) {
      details.push({
        label: "Registered before x days or less",
        value: filter.registeredBefore
      });
    }

    if (filter.lastActivityFrom) {
      details.push({
        label: "Last activity from",
        value: filter.lastActivityFrom
      });
    }

    if (filter.lastActivityTo) {
      details.push({
        label: "Last activity to",
        value: filter.lastActivityTo
      });
    }

    if (filter.lastTransactionFrom) {
      details.push({
        label: "Last transaction date from",
        value: filter.lastTransactionFrom
      });
    }

    if (filter.lastTransactionTo) {
      details.push({
        label: "Last transaction date to",
        value: filter.lastTransactionTo
      });
    }

    if (filter.balanceFrom) {
      details.push({
        label: "Balance from",
        value: filter.balanceFrom
      });
    }

    if (filter.balanceTo) {
      details.push({
        label: "Balance to",
        value: filter.balanceTo
      });
    }

    if (filter.cardNotOrdered) {
      details.push({
        label: "Card not ordered",
        value: filter.cardNotOrdered ? "true" : "false"
      });
    }

    if (filter.cardNotActivated) {
      details.push({
        label: "Card not activated",
        value: filter.cardNotActivated ? "true" : "false"
      });
    }

    return <AppInfoList items={details} />;
  }
}
