import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'contentManagement';

export default class ContentManagementModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'contentManagement.*';
  }

  getNavigation() {
    return [
      {
        label: 'Content Management',
        icon: 'cog',
        path: null,
        slug: 'content-management',
        permission: 'content.*',
        children: [
          {
            label: 'Config',
            icon: 'cogs',
            path: '/config',
            permission: 'content.config.*'
          },
          {
            label: 'Faq',
            icon: 'question-circle',
            path: '/faq',
            slug: 'faq',
            permission: 'content.faq.*'
          },
          {
            label: 'Countries',
            path: '/countries',
            icon: 'map',
            permission: 'content.countries.*'
          },
          {
            label: 'Currencies',
            path: '/currencies',
            icon: 'money',
            permission: 'content.config.*'
          },
          {
            label: 'Transaction Categories',
            path: '/transaction-categories',
            icon: 'exchange',
            permission: 'content.transactionCategories.*'
          },
          {
            label: 'Transaction Merchant',
            path: '/transaction-merchant',
            icon: 'exchange',
            permission: 'content.transactionMerchants.*'
          },
          {
            label: 'Merchant Category Code',
            path: '/merchant-category-code',
            icon: 'barcode',
            permission: 'content.mcc.*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}config`}
        load={() => import('../config/Config')}
      />,
      <AppRoute path={`${currentUrl}faq`} load={() => import('../faq/Faq')} />,
      <AppRoute
        path={`${currentUrl}countries`}
        load={() => import('./countries/Countries')}
      />,
      <AppRoute
        path={`${currentUrl}currencies`}
        load={() => import('./currencies/Currencies')}
      />,
      <AppRoute
        path={`${currentUrl}transaction-categories`}
        load={() => import('./transaction-categories/TransactionCategories')}
      />,
      <AppRoute
        path={`${currentUrl}merchant-category-code`}
        load={() => import('./merchant-category-code/MerchantCategoryCode')}
      />,
      <AppRoute
        path={`${currentUrl}transaction-merchant`}
        load={() => import('./transaction-merchant/TransactionMerchant')}
      />
    ];
  }
}
