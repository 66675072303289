import React from 'react';

export default class AppBadge extends React.Component {

  _getType() {
    if(this.props.type) {
      return this.props.type;
    }

    let type = 'success';
    let text = this.props.text;

    if(text.substr(0, 4).toLowerCase() == 'fail') {
      type = 'danger';
    }

    if(text.substr(0, 7).toLowerCase() == 'pending') {
      return 'info';
    }

    return type;
  }

  render() {
    let icon = null;

    if(this.props.icon) {
      icon = <i className={'fa fa-' + this.props.icon}></i>
    }

    let text = this.props.text;

    if(icon) text = " " + text;

    let type = this._getType();

    return (
      <span className={'badge badge-' + type}>{icon} {text}</span>
    )
  }
}


AppBadge.defaultProps = {
  icon: null,
  text: 'Success',
  type: null
};
