import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Chart from 'chart.js';
import { Link } from 'react-router-dom';
import { AppTable } from '../../index';
import UserModel from '../users/all-users/models/UserModel';
import AppBadge from '../../components/elements/icon/AppBadge';
import { LPOverridable } from '../../components';
import NewUserWidget from './components/NewUsersWidget';
import NewUsersTrendWidget from './components/NewUsersTrendWidget';
import TransactionsWidget from './components/TransactionsWidget';
import KycProcessModel from '../kyc/models/KycProcessModel';

interface HTMLInputElement extends HTMLElement {
  getContext(arg0: string): any;
  /**
   * Returns the value of the data at the cursor's current position.
   */
  value: string;
  history: any;
}

declare global {
  interface Window {
    barChartConfig: any;
    myScatter: any;
  }
}

export default class DashboardPage extends Component<HTMLInputElement, any> {
  _tableComponent: any;

  constructor(props: Readonly<HTMLInputElement>) {
    super(props);
    this.state = {
      loading: false,
      totalProjects: 5,
      lastWeek: 10,
      lastMonth: 14,
      lastYear: 29,
      topSchools: [],
      data: [],
      schoolNames: [],
      labels: ['1', '2', '3', '4', '5', '6', '7']
    };
  }

  componentDidMount() {
    // this.props.history.push('chat');
    Chart.defaults.global.defaultFontColor = 'white';
  }

  /**
   * Render customer chart
   */
  renderCustomerChart() {
    var ctxx = document.getElementById('customerChart') as HTMLInputElement;
    var ctx = ctxx.getContext('2d');
    var customerChartData = {
      // labels: this.getAllSchools(),
      datasets: [
        {
          label: 'Total collected £',
          // data: this.getAllSchoolsData(),
          backgroundColor: 'rgba(54, 162, 235, 1)',
          hoverBackgroundColor: '#66A2EB'
        }
      ]
    };

    var customerChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: customerChartData,
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                beginAtZero: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              stacked: true
            }
          ]
        }
      }
    });
  }

  _renderStatus(item: any) {
    let check = '';
    if (item.kycStatus) {
      check = item.kycStatus;
    } else {
      check = item;
    }
    switch (check) {
      case 'approved':
        return <AppBadge text="Approved" icon="check" />;
      case 'verified':
        return <AppBadge text="Verified" icon="check" />;
      case 'declined':
        return <AppBadge text="Declined" icon="times" type="danger" />;
      case 'rejected':
        return <AppBadge text="Rejected" icon="times" type="danger" />;
      case 'pending_review':
        return <AppBadge text="Pending review" icon="clock-o" type="info" />;
      case 'in_progress':
        return <AppBadge text="In progress" icon="clock-o" type="info" />;
      case 'document_review_in_progress':
        return (
          <AppBadge
            text="Document review in progress"
            icon="clock-o"
            type="info"
          />
        );
      case 'pending_documents_from_user':
        return (
          <AppBadge
            text="Document review in progress"
            icon="clock-o"
            type="info"
          />
        );
      default:
        return <AppBadge text={check} icon="clock-o" type="info" />;
    }
  }

  render() {
    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard
        </h1>
        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <LPOverridable name="pages.home.widgets.top">
            <NewUserWidget />
            <NewUsersTrendWidget />
            <TransactionsWidget />
          </LPOverridable>
        </div>

        <LPOverridable name="pages.home.widgets.bottom">
          <div className="row">
            <div className="col-lg-12">
              <div className="activated-cards">
                {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
                <div className="dark-blur"></div>
                <div className="box-header">
                  <h2>
                    <i className="fa fa-users"></i> Latest Users
                  </h2>
                  <div className="box-header-action">
                    <Link to="/users/all-users" className="button">
                      List all
                    </Link>
                  </div>
                </div>
                <div className="box-body">
                  <AppTable
                    title={null}
                    onInit={(tableComponent: any) =>
                      (this._tableComponent = tableComponent)
                    }
                    filter={null}
                    paginate={false}
                    params={{
                      limit: 10
                    }}
                    parseUrl={false}
                    order={[
                      {
                        field: 'id',
                        direction: 'desc'
                      }
                    ]}
                    model={UserModel}
                    renderCell={(
                      column: { name: any },
                      item: { kycStatus: any },
                      index: any
                    ) => {
                      switch (column.name) {
                        case 'kycStatus':
                          return this._renderStatus(item);
                      }
                    }}
                    getColumnActions={(member: { id: any }, index: any) => {
                      return (
                        <div>
                          <Link
                            to={`/users/all-users/details/${member.id}`}
                            className="button-info"
                          >
                            Details
                          </Link>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <LPOverridable name="pages.home.widgets.last-bottom">
            <div className="row">
              <div className="col-lg-12">
                <div className="activated-cards">
                  {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
                  <div className="dark-blur"></div>
                  <div className="box-header">
                    <h2>
                      <i className="fa fa-users"></i> Latest Account
                      Verifications
                    </h2>
                    <div className="box-header-action">
                      <Link to="/users/all-users" className="button">
                        List all
                      </Link>
                    </div>
                  </div>
                  <div className="box-body">
                    <AppTable
                      title={null}
                      onInit={tableComponent =>
                        (this._tableComponent = tableComponent)
                      }
                      filter={null}
                      paginate={false}
                      params={{
                        limit: 10
                      }}
                      parseUrl={false}
                      order={[
                        {
                          field: 'id',
                          direction: 'desc'
                        }
                      ]}
                      model={KycProcessModel}
                      renderCell={(column, item, index) => {
                        switch (column.name) {
                          case 'status':
                            return this._renderStatus(item.status);
                          default:
                            return undefined;
                        }
                      }}
                      getColumnActions={(member, index) => {
                        return (
                          <div>
                            <Link
                              to={`/kyc/details/${member.id}`}
                              className="button-info"
                            >
                              Details
                            </Link>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </LPOverridable>
        </LPOverridable>
      </div>
    );
  }
}
