import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BreadcrumbHelper from '../../../../util/BreadcrumbHelper';
import AppTabs from '../../../../components/crud/tabs/AppTabs';
import AppleBasicInfo from './tabs/AppleBasicInfo';
import AppleInstall from './tabs/AppleInstall';
import AppleUninstall from './tabs/AppleUninstall';
import AppleCrashes from './tabs/AppleCrashes';
import CompareAndroidApple from '../comonents/CompareAndroidApple';
import AppButtonModal from '../../../../components/modal/AppButtonModal';
import AppleReportsUploadField from './components/AppleReportsUploadField';

class AnalyticsApple extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'basic'
    };

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Apple',
        link: '/analytics/apple'
      }
    ]);
  }

  getTabs() {
    const basicInfo = <AppleBasicInfo fetcher={this._fetcher} />;
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      },
      {
        id: 'installations',
        icon: 'mobile',
        title: 'Installations',
        component: <AppleInstall {...this.props} />
      },
      {
        id: 'uninstallations',
        icon: 'ban',
        title: 'Uninstallations',
        component: <AppleUninstall {...this.props} />
      },
      {
        id: 'crashes',
        icon: 'chain-broken',
        title: 'Crashes',
        component: <AppleCrashes {...this.props} />
      },
      {
        id: 'compare',
        icon: 'files-o',
        title: 'Compare with Android',
        component: <CompareAndroidApple {...this.props} />
      }
    ];
    return allTabs;
  }

  _renderContent() {
    return (
      <div style={{ marginTop: 20 }}>
        <AppTabs
          onTabChange={activeTab => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs()}
        />
      </div>
    );
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    return (
      <div className="dashboard-management">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <div style={{ maxWidth: 300 }}>
            <AppButtonModal
              buttonText={
                <span>
                  <i className="fa fa-upload" aria-hidden="true" /> Upload Apple
                  Reports
                </span>
              }
              buttonClassName="button-success"
            >
              <AppleReportsUploadField />
            </AppButtonModal>
          </div>
        </div>
        {this._renderContent()}
      </div>
    );
  }
}
// Inject router
AnalyticsApple.contextTypes = {
  router: PropTypes.object
};

export default AnalyticsApple;
