import React from 'react';
import BulkLoadRecordModel from '../models/BulkLoadRecordModel';
import { AppPage, AppWidget } from '../../../../components';
import BulkLoadInfo from '../components/BulkLoadInfo';
import { AppFetcher } from '../../../../components';
import BulkLoadRecords from '../components/BulkLoadRecords';
import BulkLoadRecordInfo from '../components/record/BulkLoadRecordInfo';
import BulkLoadRecordFields from '../components/record/BulkLoadRecordFields';
import BulkLoadRecordError from '../components/record/BulkLoadRecordError';
import BulkLoadRecordServiceCall from '../components/record/BulkLoadRecordServiceCall';
import BreadcrumbHelper from '../../../../util/BreadcrumbHelper';

export default class BulkLoadRecordDetails extends React.Component {

  constructor(props) {
    super(props);

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Bulk Load',
        link: '/tools/bulk-load'
      }
    ]);
  }

  _renderContent(item) {

    BreadcrumbHelper.addCrumb(item.bulkLoad.name, '/tools/bulk-load/details/' + item.bulkLoad.id);
    BreadcrumbHelper.addCrumb('Token ' + item.token);

    return (
      <AppPage title={'Card load record details'} icon={'info'}>
        <div className='row'>
          <div className='col-sm-4'>
            <AppWidget title={'Basic info'} icon={null} padded>
              <BulkLoadRecordInfo record={item}/>
            </AppWidget>

            <AppWidget title={'Load error'} icon={null} padded>
              <BulkLoadRecordError record={item}/>
            </AppWidget>
          </div>

          <div className='col-sm-8'>
            <AppWidget title='Record fields'>
              <BulkLoadRecordFields record={item}/>
            </AppWidget>
            <AppWidget title='Service call'>
              <BulkLoadRecordServiceCall record={item}/>
            </AppWidget>
          </div>
        </div>

      </AppPage>
    );
  }
  render() {
    let bulkId = this.props.match.params.id;
    let recordId = this.props.match.params.recordId;

    return (
      <AppFetcher
          ref={fetcher => this._fetcher = fetcher}
          model={BulkLoadRecordModel}
          id={{
            id: recordId,
            bulkLoadId: bulkId
          }}
          renderContent={record => this._renderContent(record)}
        >
        </AppFetcher>
    );
  }
}
